import classNames from "classnames";
import React from "react";
import moment from "moment/moment";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import { ReactComponent as Temp } from '../../assets/termometer.svg';
import { ReactComponent as Cloud } from '../../assets/rainy-cloud.svg';
import { ReactComponent as Up } from '../../assets/up-arrow.svg';
import { ReactComponent as UpWhite } from '../../assets/arrow-up-white.svg';
import { ReactComponent as Down } from '../../assets/down-arrow.svg';
import { ReactComponent as DownWhite } from '../../assets/arrow-down-white.svg';
import { ReactComponent as WaterDrop } from '../../assets/water-drop.svg';
import { ReactComponent as TempWhite } from '../../assets/termometer-white.svg';
import { ReactComponent as CloudWhite } from '../../assets/cloud-white.svg';
import { weekDays } from '../../utils';

export function Dailies({ days = [] }) {
  if (days.length === 0) {
    return <div className="no-forecast daily weather-table" >
      <div className="no-forecast-text">Daily forecast unavailable</div>
    </div>
  } 
  return <div className="daily weather-table">
    <div className="row">
      <div className="row-description"></div>
      {days.map(day => {
        return <div key={day.time} className={classNames('title', "cell")}>
          {weekDays[moment(day.time * 1000).format('dddd')]}
        </div>
      })}
    </div>
    <div className="row bottom-border">
      <div className="row-description"></div>
      {days.map((day) => {
        return (
          <div className="cell" key={day.time}>
            <div className={classNames('weather-icon', day.icon)}/>
          </div>
        )
      })}
    </div>
    <div className="row bottom-border">
      <div className="row-description big">
        <Up className="big light"/>
        <UpWhite className="big dark"/>
        <Temp className="big light"/>
        <TempWhite className="big dark"/>
      </div>
      {days.map((day) => {
        return (
          <div className={classNames("cell", "temp", "big")} key={day.time}>
            <FormattedDataPoint type='tempf' value={day.temperatureMax} suff="°" valueTransform={Math.round} />
          </div>
        )
      })}
    </div>
    <div className="row bottom-border">
      <div className="row-description big">
        <Down className="big light"/>
        <DownWhite className="big dark"/>
        <Temp className="big light"/>
        <TempWhite className="big dark"/>
      </div>
      {days.map((day) => {
        return (
          <div className={classNames("cell", "temp", "big")} key={day.time}>
            <FormattedDataPoint type='tempf' value={day.temperatureMin} suff="°" valueTransform={Math.round} />
          </div>
        )
      })}
    </div>
    <div className="row">
      <div className="row-description">
        <Cloud className="small light"/>
        <CloudWhite className="small dark"/>
      </div>
      {days.map(day => {
        return (
          <div className={classNames("cell", "rain-prob", "temp", "small")} key={day.time}>
            <div className="small-row">
              <span>{parseInt(day.precipProbability * 100, 10)}</span>
              <span>%</span>
            </div>
            <WaterDrop/>
          </div>
        )
      })}
    </div>
  </div>
}

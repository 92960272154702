import './App.scss';
import {useSearchParams} from "react-router-dom";
import {useState} from "react";
import {WidgetGallery} from "./widgets/WidgetGallery/WidgetGallery";
import classNames from 'classnames';
import {Screen} from "./layouts/Screen/Screen";
import {getData, isDay} from './utils';
import moment from "moment";
import {SingleWidgetSandbox} from "./widgets/SingleWidgetSandbox/SingleWidgetSandbox";

function App() {
  const [searchParams] = useSearchParams();
  const data = searchParams.get('data') ? JSON.parse(searchParams.get('data')) : getData();

  // which is closer to now, the current forecast or the first hourly forecast?
  if (Math.abs(Date.now() - data.forecast?.currently?.time * 1000) < Math.abs(Date.now() - data.forecast?.hourly?.data[0]?.time * 1000)) {
    data.forecast.hourly.data.unshift(data.forecast.currently)
  }

  // s=2 is forecast, s=1 is widgets, s=3 is the widget gallery
  const screen = parseInt(searchParams.get('s'));


  if(screen === 3)
    return <WidgetGallery/>;
  else if(screen === 4)
    return <SingleWidgetSandbox/>;
  else{
    const classes = ["wxw", data.theme];
    if(data.theme === "dynamic"){
      const day = isDay(moment(), data.forecast.lat, data.forecast.lon, data.forecast.tz);
      classes.push(day?"light": "dark");
    }

    return (
      <div id="wxw" className={classNames(classes)}>
        <Screen data={data} screenId={screen}/>
      </div>
    );
  }
}

export default App;

import React from 'react';
import {soilHumidityLabel} from '../../ambient'
import FormattedDataPoint from '../../components/FormattedDataPoint/FormattedDataPoint'
import './SoilMoisture.scss'
import {CardFooter} from '../../components/CardFooter/CardFooter';

export const SoilMoisture = ({currentDevice, type = "soil1", size}) => {
  const sensor = type.replace('soil', '')
  const moistureKey = `soilhum${sensor}`

    return (
        <>
            <div className='metric-card-body'>
                <div className='big-value'>
                    <FormattedDataPoint value={currentDevice.lastData[moistureKey]} row={currentDevice.lastData} className='big-number'
                                        type={moistureKey} center={true}/>
                </div>
            </div>
            {(currentDevice.lastData[moistureKey] || currentDevice.lastData[moistureKey] === 0) ?
                <CardFooter
                    values={[{name: "Conditions", value: soilHumidityLabel(currentDevice.lastData[moistureKey])}]}
                    size={size}
                /> : ''
            }
        </>
    );
}

import React, {useContext} from "react";
import {DataContext} from "../../contexts/DataContext";
import moment from "moment/moment";
import classNames from "classnames";
import {CardFooter} from "../../components/CardFooter/CardFooter";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import { ReactComponent as WaterDrop } from '../../assets/water-drop.svg';
import astro from "../../ambient/astro";
import {getShortMeridian, resizeUntilFits} from "../../utils";
import {getAstroValue} from "../../ambient";
import "./Daycast.scss";
import {ambient} from "../../ambient/ambient-weather-common";
const { getUserUnitI, getMasterUnitParam, convertUnit } = ambient;

export const Daycast = ({size}) => {
  const globalState = useContext(DataContext);
  const forecast = globalState.forecastData;
  const user = globalState.userData;

  if (!forecast || !user) {
    return (
      <div className='metric-card-body'>
        <div>Loading...</div>
      </div>
    )
  }

  const { daily = { data: [] } } = forecast;
  const today = daily.data
    .find(
      f => moment(f.time * 1000).startOf('day').valueOf() === moment().startOf('day').valueOf()
    );
  if(!today)
    return <div className="metric-card-body no-data">
      <div>No data to show</div>
    </div>;

  const getValue = getAstroValue({ lat: forecast.lat, lon: forecast.lon, tz: forecast.tz });
  const sunrise = getValue(astro.solar.rise, Date.now());
  const sunset = getValue(astro.solar.set, Date.now());

  let sunriseText;
  let sunsetText;
  if(user.settings.hour24 && user.settings.hour24.unit === 1){
    sunriseText = sunrise.format("H:mm").replace(' ', '');
    sunsetText = sunset.format("H:mm").replace(' ', '');
  }
  else{
    const sunriseMeridian = getShortMeridian(sunrise);
    const sunsetMeridian = getShortMeridian(sunset);
    sunriseText = sunrise.format("h:mm").replace(' ', '') + " " + sunriseMeridian;
    sunsetText = sunset.format("h:mm").replace(' ', '') + " " + sunsetMeridian;
  }
  let availableWidth;
  const mainFontStyle = {
    fontFamily: "Bahnschrift, serif",
    fontWeight: 600,
    fontStretch: "90%"
  };
  switch (size) {
    case "medium-card": availableWidth = 154;
      mainFontStyle.fontSize = "110px";
      break;
    case "large-card": availableWidth = 256;
      mainFontStyle.fontSize = "130px";
      break;
    default: availableWidth = 106;
      mainFontStyle.fontSize = "60px";
  }

  const userUnitI = getUserUnitI("tempf", user);
  const unitParam = getMasterUnitParam("tempf");
  const high = Math.round(convertUnit(unitParam, userUnitI, today.temperatureMax));
  const low = Math.round(convertUnit(unitParam, userUnitI, today.temperatureMin));

  const highSize = resizeUntilFits(high + "°", mainFontStyle, availableWidth);
  const lowSize = resizeUntilFits(low + "°", mainFontStyle, availableWidth);
  const commonSize = Math.min(lowSize.match(/\d+/)[0], highSize.match(/\d+/)[0]);

  return <>
      <div className="metric-card-body">
        <div className="cast-row high">
          <div>Hi:</div>
          <div style={{fontSize: commonSize+"px"}}>
            <FormattedDataPoint
              type='tempf'
              value={today.temperatureMax}
              suff="°"
              valueTransform={Math.round}
            />
          </div>
          <div className="with-icon">
            <div className={classNames('weather-icon', today.icon)}/>
          </div>
        </div>
        <div className="cast-row low">
          <div>Lo:</div>
          <div style={{fontSize: commonSize+"px"}}>
            <FormattedDataPoint
              type='tempf'
              value={today.temperatureMin}
              suff="°"
              valueTransform={Math.round}
            />
          </div>
          <div className="rain-prob">
            <WaterDrop/> {parseInt(today.precipProbability * 100, 10)}%
          </div>
        </div>
      </div>
      {
        size !== "small-card" ?
          <CardFooter values={[
            {
              name: "Rise",
              value: sunriseText
            },
            {
              name: "Set",
              value: sunsetText
            }
          ]}/> : null
      }
    </>;
}
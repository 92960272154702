import {getWidthOfText} from "../../utils";

export const ResizableText = ({text, availableWidth, styles}) => {
  const textStyle = {...styles};

  let currentFontSize = styles.fontSize.match(/\d+/)[0];
  const unit = styles.fontSize.replace(/[0-9.]/g, "");
  const decrement = currentFontSize / 20;
  const minimumSize = currentFontSize * 0.4;

  while(currentFontSize > minimumSize){
    currentFontSize = currentFontSize - decrement;
    textStyle.fontSize = currentFontSize + unit
    const newWidth = getWidthOfText(text, textStyle);

    if(newWidth <= availableWidth){
      break;
    }
  }

  return <span style={textStyle}>{text}</span>;
}
import {useCallback, useState} from "react";
import {getHeightOfParagraph} from "../utils";

export const useDynamicSize = (text, fontStyle) => {
  const [fontSize, setFontSize] = useState(fontStyle.fontSize);
  const ref = useCallback(node => {
    if (node !== null) {
      let currentFontSize = fontStyle.fontSize.match(/\d+/)[0];
      const unit = fontStyle.fontSize.replace(/[0-9.]/g, "");
      const decrement = currentFontSize / 20;
      const minimumSize = currentFontSize * 0.4;
      const containerWidth = node.getBoundingClientRect().width;
      const containerHeight = node.getBoundingClientRect().height;

      do{
        const textHeight = getHeightOfParagraph(text, {...fontStyle, fontSize: currentFontSize+unit}, containerWidth + "px");
        if(textHeight < containerHeight) break;
        currentFontSize = currentFontSize - decrement;
      }
      while(currentFontSize > minimumSize)

      setFontSize(currentFontSize + unit);
    }
  }, [text, fontStyle]);

  return [ref, fontSize];
}
import "./RelativeHumidity.scss";
import {CardFooter} from "../../components/CardFooter/CardFooter";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";

export function RelativeHumidity ({ currentDevice, size }) {
  return (
    <>
      <div className='metric-card-body'>
        <div className='big-value'>
          <FormattedDataPoint
            value={currentDevice.lastData.humidity}
            row={currentDevice.lastData}
            className='big-number'
            type='humidity'
            center={true}
          />
        </div>
      </div>
      <CardFooter
        values={[
          {
            name: "Dew Point",
            value:<FormattedDataPoint
              value={currentDevice.lastData.dewPoint}
              row={currentDevice.lastData}
              type='dewPoint'
              suff='°'
            />
          }
        ]}
        size={size}
      />
    </>
  )
}
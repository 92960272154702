import {makeId, toRadian} from "../../utils";
import * as d3 from "d3";
import {useD3} from "../../hooks/useD3";
import React from "react";

const directionAngles = {
  N: 352,
  NNE: 14.5,
  NE: 37,
  ENE: 59.5,
  E: 82,
  ESE: 104.5,
  SE: 127,
  SSE: 149.5,
  S: 172,
  SSW: 194.5,
  SW: 217,
  WSW: 239.5,
  W: 262,
  WNW: 284.5,
  NW: 307,
  NNW: 329.5
};
const sizes = {
  "large-card": {
    scale: 1,
    squareSize: 300,
    lineStart: 26,
    cardinalPoints: {
      N: {
        x: 145,
        y: 20,
        fontSize: 22
      },
      S: {
        x: 150,
        y: 296,
        fontSize: 22
      },
      E: {
        x: 280,
        y: 155,
        fontSize: 22
      },
      W: {
        x: 0,
        y: 155,
        fontSize: 22
      }
    }
  },
  "medium-card": {
    scale: 0.71,
    squareSize: 230,
    lineStart: 26,
    cardinalPoints: {
      N: {
        x: 107,
        y: 20,
        fontSize: 20
      },
      S: {
        x: 107,
        y: 222,
        fontSize: 20
      },
      E: {
        x: 210,
        y: 120,
        fontSize: 20
      },
      W: {
        x: 2,
        y: 120,
        fontSize: 20
      }
    }
  },
  "small-card": {
    scale: 0.5,
    squareSize: 168,
    lineStart: 22,
    cardinalPoints: {
      N: {
        x: 77,
        y: 15,
        fontSize: 18
      },
      S: {
        x: 77,
        y: 162,
        fontSize: 18
      },
      E: {
        x: 152,
        y: 90,
        fontSize: 18
      },
      W: {
        x: 0,
        y: 90,
        fontSize: 18
      }
    }
  }
}
const createCircleSection = (svg, centerX, centerY, radius, angle) => {
  const gradientId = makeId(8);
  const lg = svg.append("defs").append("linearGradient")
    .attr("id", gradientId)
    .attr("gradientTransform", `rotate(${90})`);

  lg.append("stop")
    .attr("offset", "0%")
    .style("stop-color", "#9c0000")//end in red
    .style("stop-opacity", 1)

  lg.append("stop")
    .attr("offset", "100%")
    .style("stop-color", "#eec831")//start in blue
    .style("stop-opacity", 1);

  const arc = d3.arc()
    .innerRadius(0)
    .outerRadius(radius)
    .startAngle(0)
    .endAngle(toRadian(16));
  svg.append("path")
    .attr("transform", `translate(${centerX},${centerY}) rotate(${angle})`)
    .style("fill", `url(#${gradientId})`)
    .attr("d", arc());
}
export const RoseGraph = ({currentDevice, size}) => {
  const scale = sizes[size].scale;
  const squareSize = sizes[size].squareSize;
  const d3ref = useD3(
    (svg) => {
      svg.selectAll("*").remove();

      const circleCenter = squareSize / 2;
      const radius = 124;
      const diameter = radius * 2;
      //paint the horizontal and vertical lines
      const lineLength = diameter * scale;
      const lineStart = sizes[size].lineStart;
      svg.append('line')
        .attr('x1', circleCenter)
        .attr('y1', lineStart)
        .attr('x2', circleCenter)
        .attr('y2', lineStart + lineLength)
        .attr('stroke', '#b6b6b6');
      svg.append('line')
        .attr('x1', lineStart)
        .attr('y1', circleCenter)
        .attr('x2', lineStart + lineLength)
        .attr('y2', circleCenter)
        .attr('stroke', '#b6b6b6');

      //paint the circles
      svg.append("circle")
        .attr("cx", circleCenter)
        .attr("cy", circleCenter)
        .attr("r",42 * scale)
        .style("stroke", "#b6b6b6")
        .style("stroke-width", "1px")
        .style("fill", "transparent");
      svg.append("circle")
        .attr("cx", circleCenter)
        .attr("cy", circleCenter)
        .attr("r",70 * scale)
        .style("stroke", "#b6b6b6")
        .style("stroke-width", "1px")
        .style("fill", "transparent");
      svg.append("circle")
        .attr("cx", circleCenter)
        .attr("cy", circleCenter)
        .attr("r",100 * scale)
        .style("stroke", "#b6b6b6")
        .style("stroke-width", "1px")
        .style("fill", "transparent");

      //paint arcs of wind directions
      if(currentDevice?.lastData?.hl?.winddir){
        const max = Math.max(...Object.values(currentDevice?.lastData?.hl?.winddir));
        for(let direction of Object.keys(currentDevice?.lastData?.hl?.winddir)){
          if(directionAngles[direction]){
            const arcRadius = currentDevice?.lastData?.hl?.winddir[direction] * radius / max;
            createCircleSection(svg, circleCenter, circleCenter, arcRadius * scale, directionAngles[direction]);
          }
        }
      }

      //paint the outer circle
      svg.append("circle")
        .attr("cx", circleCenter)
        .attr("cy", circleCenter)
        .attr("r",radius * scale)
        .style("stroke-width", "1px")
        .style("fill", "transparent")
        .classed("outer-circle", true);

      //paint texts
      svg.append('text')
        .attr('x', sizes[size].cardinalPoints.N.x)
        .attr('y', sizes[size].cardinalPoints.N.y)
        .style("font-size", sizes[size].cardinalPoints.N.fontSize)
        .text("N");
      svg.append('text')
        .attr('x', sizes[size].cardinalPoints.S.x)
        .attr('y', sizes[size].cardinalPoints.S.y)
        .style("font-size", sizes[size].cardinalPoints.S.fontSize)
        .text("S");
      svg.append('text')
        .attr('x', sizes[size].cardinalPoints.W.x)
        .attr('y', sizes[size].cardinalPoints.W.y)
        .style("font-size", sizes[size].cardinalPoints.W.fontSize)
        .text("W");
      svg.append('text')
        .attr('x', sizes[size].cardinalPoints.E.x)
        .attr('y', sizes[size].cardinalPoints.E.y)
        .style("font-size", sizes[size].cardinalPoints.E.fontSize)
        .text("E");
    }
  );

  return <svg
    ref={d3ref}
    style={{
      width: squareSize + "px",
      height: squareSize + "px",
    }}
  />;
}
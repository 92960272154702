const R = require('ramda')
const getRandomValues = require('get-random-values')

/* jshint ignore:start */

module.exports = {
  // takes: object
  // returns: array containing the keys that are truthy
  trueKeys : R.compose(R.keys, R.pickBy(function(v,k){ return v })),
   // takes: two arrays
   // returns: bool 
  doArraysIntersect : R.curry(function(firstList, secondList){
     return R.reduce(function(prev, curr){
        return prev || firstList.indexOf(curr) > -1
      }, false, secondList)
  }),
  // takes: path (str, arr), arr of objs
  // returns: obj where keys are the path value and values are an array of objs w/that common key
  groupedBy : R.curry(function(path, arr){
    if (typeof path === 'string') path = path.split('.')
    return arr.reduce(function(prev, curr){
      const val = R.path(path, curr)
      prev[val] = prev[val] || []
      prev[val].push(curr)
      return prev
    }, {});
  }),
  stripTags : function (str) {
    return str.replace(/(<([^>]+)>)/ig,"")
  },
  addPropToObj : R.curry(function (prop, fn){
    return function (obj) {
      return R.set(R.lensProp(prop), typeof fn === 'function' ? fn(obj) : fn, R.clone(obj))
    }
  }),
  guid : function(){
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      // eslint-disable-next-line no-mixed-operators
      const r = getRandomValues(new Uint8Array(1))[0]%16|0, v = c === 'x' ? r : (r&0x3|0x8)
      return v.toString(16)
    })
  },
  // (a -> Bool) -> Object -> [String]
  // return list of keys that pass predicate on value
  keysWith : R.curry(function (pred, obj){
    return R.pipe(
      R.toPairs,
      R.filter(R.pipe(R.nth(1), pred)),
      R.map(R.nth(0))
    )(obj)
  }),
};
/* jshint ignore:end */

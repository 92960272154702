import "./UVIndex.scss";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
const indexDescriptions = [
    'Low',
    'Low',
    'Moderate',
    'Moderate',
    'Moderate',
    'High',
    'High',
    'Very High',
    'Very High',
    'Very High',
    'Very High',
    'Extreme'
];
export function UVIndex ({currentDevice}) {
  const indexDescription = currentDevice.lastData.uv !== undefined && currentDevice.lastData.uv < 11 ?
    indexDescriptions[currentDevice.lastData.uv]:
    indexDescriptions[11];

  return (
    <>
      <div className='uv-card-body'>
        <div className='big-value'>
          <FormattedDataPoint
            value={currentDevice.lastData.uv}
            row={currentDevice.lastData}
            className='big-number'
            type='uv'
            center={true}
          />
        </div>
        <div className="description">{indexDescription}</div>
        <div className="uv-range"/>
      </div>
    </>
  )
}
import moment from "moment";
import {getAstroValue} from "../../ambient";
import astro from "../../ambient/astro";
import {getShortMeridian} from "../../utils";
import {ambient} from "../../ambient/ambient-weather-common";
import {Hourlies} from "./Hourlies";
import {Dailies} from "./Dailies";
import "./Forecast2.scss";
const { getUserUnitI, getMasterUnitParam } = ambient;

export const Forecast2 = ({forecast, user, data }) => {
  const { hourly = { data: [] }, daily = { data: [] }, currently = {} } = forecast;
  const days = daily.data
    .filter(
      f => moment(f.time * 1000).tz(data.tz).startOf('day').valueOf() >= moment().tz(data.tz).startOf('day').valueOf()
    )
    .slice(0, 6);
  const getValue = getAstroValue({ lat: forecast.lat, lon: forecast.lon, tz: forecast.tz });
  const hoursToShow = hourly.data.slice(0, 8);

  const sunrise = getValue(astro.solar.rise, Date.now());
  const sunset = getValue(astro.solar.set, Date.now());

  let sunriseText;
  let sunsetText;
  if(user.settings.hour24 && user.settings.hour24.unit === 1){
    sunriseText = sunrise.format("H:mm").replace(' ', '');
    sunsetText = sunset.format("H:mm").replace(' ', '');
  }
  else{
    const sunriseMeridian = getShortMeridian(sunrise);
    const sunsetMeridian = getShortMeridian(sunset);
    sunriseText = sunrise.format("h:mm").replace(' ', '') + " " + sunriseMeridian;
    sunsetText = sunset.format("h:mm").replace(' ', '') + " " + sunsetMeridian;
  }

  const userUnitI = getUserUnitI("tempf", user);
  const unitParam = getMasterUnitParam("tempf");

  const today = days.length > 0? days[0]:null;

  return <div className="forecast forecast2">
    <div className="header">
      <div>Location: {forecast.location}</div>
      <div>{ambient.DATA_SPEC[unitParam].units[userUnitI]}</div>
      <div>
        <span>Sunrise {sunriseText}</span>
        <span>Sunset {sunsetText}</span>
      </div>
    </div>
    <Hourlies
      hoursToShow={hoursToShow}
      user={user}
      tz={forecast.tz}
      today={today}
    />
    <Dailies days={days.slice(1, days.length)}
    />
  </div>
}
import "./LeakDetect.scss";

export function LeakDetect({currentDevice, size}) {

  return (
    <>
      <div className='metric-card-body'>
        <div className="leak-wrapper">
          <div className='leak-item'>
            <div className="label">{size === 'large-card' ? 'Leak 1' : 'L1'}</div>
            <div
              className="leak-value">{currentDevice.lastData.leak1 !== undefined ? (currentDevice.lastData.leak1 === 0 ? 'OK' : currentDevice.lastData.leak1 === 1 ? 'LEAK' : 'Offline') :
              <div>- -</div>}</div>
          </div>
          <div className='leak-item'>
            <div className="label">{size === 'large-card' ? 'Leak 3' : 'L3'}</div>
            <div
              className="leak-value">{currentDevice.lastData.leak3 !== undefined ? (currentDevice.lastData.leak3 === 0 ? 'OK' : currentDevice.lastData.leak3 === 1 ? 'LEAK' : 'Offline') :
              <div>- -</div>}</div>
          </div>
          <div className='leak-item'>
            <div className="label">{size === 'large-card' ? 'Leak 2' : 'L2'}</div>
            <div
              className="leak-value">{currentDevice.lastData.leak2 !== undefined ? (currentDevice.lastData.leak2 === 0 ? 'OK' : currentDevice.lastData.leak2 === 1 ? 'LEAK' : 'Offline') :
              <div>- -</div>}</div>
          </div>
          <div className='leak-item'>
            <div className="label">{size === 'large-card' ? 'Leak 4' : 'L4'}</div>
            <div
              className="leak-value">{currentDevice.lastData.leak4 !== undefined ? (currentDevice.lastData.leak4 === 0 ? 'OK' : currentDevice.lastData.leak4 === 1 ? 'LEAK' : 'Offline') :
              <div>- -</div>}</div>
          </div>
        </div>
      </div>
    </>
  )
}
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import moment from "moment-timezone";
import { path } from "ramda";

export function ErrorWidget({ currentDevice, type }) {
  const ctx = useContext(DataContext);
  const format = (path(['userData', 'settings', 'hour24'], ctx) === 1) ? "HH:mm MMM D, YYYY" : "h:mma MMM D, YYYY"
  let show = 'Device has been removed from AWN'
  if (type === 'old-data') {
    show = (
      <>
        Device Offline<br/>
        Last report at<br/>{moment().tz(ctx.tz).format(format)} 
      </>
    )
  }
  return <div className='metric-card-body'>{show}</div>
}

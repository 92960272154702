import moment from "moment/moment";
import React from "react";
import {getShortMeridian} from "../../utils";
import classNames from "classnames";
import {ambient} from "../../ambient/ambient-weather-common";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import { ReactComponent as Temp } from '../../assets/termometer.svg';
import { ReactComponent as TempWhite } from '../../assets/termometer-white.svg';
import { ReactComponent as PercentDrop } from '../../assets/humidity.svg';
import { ReactComponent as PercentDropWhite } from '../../assets/humidity-white.svg';
import { ReactComponent as Wind } from '../../assets/wind.svg';
import { ReactComponent as WindWhite } from '../../assets/wind-white.svg';
import { ReactComponent as Cloud } from '../../assets/rainy-cloud.svg';
import { ReactComponent as CloudWhite } from '../../assets/cloud-white.svg';
import { ReactComponent as WaterDrop } from '../../assets/water-drop.svg';
import { ReactComponent as WindDirection } from '../../assets/blue-arrow.svg';
const { isSomething } = ambient;

export function Hourlies({hoursToShow = [], user, tz}) {
  if (hoursToShow.length === 0) {
    return <div className="no-forecast hourly weather-table" >
      <div className="no-forecast-text">Hourly forecast unavailable</div>
    </div>
  }
  const humidities = hoursToShow.map(f => ({
    value: Math.round(f.humidity * 100),
    time: f.time
  }));
  const todayTime = moment(hoursToShow[0]?.time * 1000).startOf('day')
  const getAlt = (time) => {
    const thisTime = moment(time * 1000);
    if (thisTime.dayOfYear() === todayTime.dayOfYear()) {
      return;
    }
    if (Math.floor(moment.duration(thisTime.diff(todayTime)).asDays()) > 1) {
      return;
    }
    return 'alt';
  }

  return <div className="hourly weather-table">
    <div className="row">
      <div className="row-description"></div>
      {hoursToShow.map((hour, i) => {
        let hourText = 'Now';
        const m = moment(hour.time * 1000).tz(tz);
        if (i > 0) {
          if(user.settings.hour24 && user.settings.hour24.unit === 1){
            hourText = m.format('H');
          }
          else{
            hourText = m.format('h') + getShortMeridian(m);
          }
        }
        if (m.hour() === 0) {
          hourText = m.format('M/D');
        }
        return (
          <div key={`hour-${i}`} className={classNames('title', getAlt(hour.time), "cell")}>
            {hourText}
          </div>
        )
      })}
    </div>
    <div className="row">
      <div className="row-description"></div>
      {hoursToShow.map((hour, i) => {
        return (
          <div className="cell" key={`hour-${i}`}>
            <div className={classNames('weather-icon', hour.icon)}/>
          </div>
        )
      })}
    </div>
    <div className="row bottom-border">
      <div className="row-description">
        <Temp className="big light"/>
        <TempWhite className="big dark"/>
      </div>
      {hoursToShow.map((hour, i) => {
        return (
          <div className={classNames("cell", "temp", "big")} key={`hour-${i}`}>
            <FormattedDataPoint type='tempf' value={hour.temperature} suff="°" valueTransform={Math.round} />
          </div>
        )
      })}
    </div>
    <div className="row bottom-border">
      <div className="row-description">
        <PercentDrop className="small light"/>
        <PercentDropWhite className="small dark"/>
      </div>
      {humidities.map((humidity, i) => <div key={`humidity-${i}`} className={classNames('cell', getAlt(humidity.time), "small")}>
        <span>{humidity.value}</span>
        <span>%</span>
      </div>)}
    </div>
    <div className="row bottom-border">
      <div className="row-description">
        <Wind className="small light"/>
        <WindWhite className="small dark"/>
      </div>
      {hoursToShow.map((hour, i) => {
        return (
          <div className={classNames("cell", "wind", "small")} key={`hour-${i}`}>
            <FormattedDataPoint type='windspeedmph' value={hour.windSpeed} valueTransform={Math.round} suff=" " />
            <WindDirection style={isSomething(hour.windBearing) ? { transform: `rotate(${hour.windBearing + 45}deg)` } : {}}/>
          </div>
        )
      })}
    </div>
    <div className="row">
      <div className="row-description">
        <Cloud className="small light"/>
        <CloudWhite className="small dark"/>
      </div>
      {hoursToShow.map((hour, i) => {
        return (
          <div className={classNames("cell", "rain-prob", "small")} key={`hour-${i}`}>
            <div className="small-row">
              <span>{parseInt(hour.precipProbability * 100, 10)}</span>
              <span>%</span>
            </div>
            <WaterDrop/>
          </div>
        )
      })}
    </div>
  </div>;
}
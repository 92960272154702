import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import {CardFooter} from "../../components/CardFooter/CardFooter";

export function Temperature ({ currentDevice }) {
  return (
    <>
      <div className='metric-card-body'>
        <div className='big-value'>
          <FormattedDataPoint
            value={currentDevice.lastData.tempf}
            row={currentDevice.lastData}
            className='big-number'
            type='tempf'
            center={true}
          />
        </div>
      </div>
      <CardFooter
        values={[{
          name: "Feels Like",
          value: <FormattedDataPoint
            row={currentDevice.lastData}
            type='feelsLike'/>
        }]}
      />
    </>
  )
}
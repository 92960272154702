import { curry, path, pipe, groupWith, F, all } from 'ramda'
import moment from 'moment-timezone'
import { ambient } from './ambient-weather-common'
const { getMasterUnitParam, convertUnit, getUserUnitI  } = ambient

export function windDirLabel (val) {
  if (val < 11.25) {
    val = 'N'
  } else if (val < 33.75) {
    val = 'NNE'
  } else if (val < 56.25) {
    val = 'NE'
  } else if (val < 78.75) {
    val = 'ENE'
  } else if (val < 101.25) {
    val = 'E'
  } else if (val < 123.75) {
    val = 'ESE'
  } else if (val < 146.25) {
    val = 'SE'
  } else if (val < 168.75) {
    val = 'SSE'
  } else if (val < 191.25) {
    val = 'S'
  } else if (val < 213.75) {
    val = 'SSW'
  } else if (val < 236.25) {
    val = 'SW'
  } else if (val < 258.75) {
    val = 'WSW'
  } else if (val < 281.25) {
    val = 'W'
  } else if (val < 303.75) {
    val = 'WNW'
  } else if (val < 326.25) {
    val = 'NW'
  } else if (val < 348.75) {
    val = 'NNW'
  } else {
    val = 'N'
  }
  return val
}

export const getAstroValue = curry((currentDevice, fn, t) => {
  const theTime = typeof t === 'object' ? t.valueOf() : t
  const lat = path(['lat'], currentDevice) || path(['info', 'coords', 'coords', 'lat'], currentDevice)
  const lon = path(['lon'], currentDevice) || path(['info', 'coords', 'coords', 'lon'], currentDevice)
  const tz = path(['tz', 'name'], currentDevice) || path(['tz'], currentDevice)
  return moment.tz(parseInt(fn(theTime, lat, lon), 10), tz)
})

export const dataHas = curry(function (data, paths) {
  return pipe(
    groupWith(F),
    all((pth) => {
      return path(pth, data) || path(pth, data) === 0
    })
  )(Array.isArray(paths) ? paths : [paths])
})

export const isSomething = v => v || v === 0

export function soilHumidityLabel (val) {
  let value = 'Very Dry'
  if (val > 87) {
    value = 'Very Wet'
  } else if (val > 73) {
    value = 'Wet'
  } else if (val > 53) {
    value = 'Moist'
  } else if (val > 27) {
      value = 'Dry'
  }
  return value
}
export const convertUnitForUser = curry(function (user, param, val) {
  const unitParam = getMasterUnitParam(param)
  const userUnitI = getUserUnitI(param, user)
  if (unitParam) {
    return parseFloat(convertUnit(unitParam, userUnitI, val))
  } else if (/humidity/.test(param) || param === 'uv' || param === 'pm25') {
    return parseInt(val, 10)
  } else if (param === 'pm25_24h') {
    return parseFloat(val).toFixed(1)
  }
  return val
})

export const widgetUsesDefaultLocation = type => {
  return /cast$/.test(type) || type === 'time'
}
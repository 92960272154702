import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import {CardFooter} from "../../components/CardFooter/CardFooter";

export function DewPoint ({ currentDevice }) {
  return (
    <>
      <div className='metric-card-body'>
        <div className='big-value'>
          <FormattedDataPoint
            value={currentDevice.lastData.dewPoint}
            row={currentDevice.lastData}
            className='big-number'
            type='dewPoint'
            center={true}
          />
        </div>
      </div>
      <CardFooter
        values={[
          {
            name: "Temperature",
            value:<FormattedDataPoint
                    value={currentDevice.lastData.tempf}
                    row={currentDevice.lastData}
                    type='tempf'
                  />
            },
            {
              name: "Humidity",
              value:<FormattedDataPoint
                      value={currentDevice.lastData.humidity}
                      row={currentDevice.lastData}
                      type='humidity'
                    />
              }
          ]}
      />
    </>
  )
}
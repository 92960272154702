import React, {useContext} from "react";
import {useD3} from "../../hooks/useD3";
import * as d3 from "d3";
import {ambient} from '../../ambient/ambient-weather-common';
import {DataContext} from "../../contexts/DataContext";
const {getMasterUnitParam, convertUnit, getUserUnitI} = ambient;

export const TempCurve = ({temps, numberSize, marginBottom}) => {
  const globalState = useContext(DataContext);
  const user = globalState.userData;

  const d3ref = useD3(
    (svg) => {
      svg.selectAll("*").remove();

      const node = svg.node();
      const width = node.getBoundingClientRect().width;
      const height = node.getBoundingClientRect().height;

      const unitParam = getMasterUnitParam("tempf");
      const userUnitI = getUserUnitI("tempf", user);
      const temperatures = temps.map(temp => {
        let converted = convertUnit(unitParam, userUnitI, temp);
        return Math.round(converted);
      });

      const points = [];
      const max = Math.max(...temperatures);
      const min = Math.min(...temperatures);
      const tempRange = max - min;
      const usableHeight = height - numberSize - marginBottom;
      const sectionWidth = width / temperatures.length;

      for (let i = 0; i < temperatures.length; i++) {
        const x = (sectionWidth * i) + (sectionWidth / 2);
        const posInRange = max - temperatures[i];
        const y = tempRange === 0?
          numberSize + usableHeight / 2:
          posInRange * usableHeight / tempRange + numberSize;
        const text = temperatures[i] + "°";
        points.push({x, y, text});
      }

      const Gen = d3.line()
        .x((p) => p.x)
        .y((p) => p.y)
        .curve(d3.curveMonotoneX);

      svg
        .append("path")
        .attr("d", Gen(points))
        .attr("fill", "none")
        .attr("stroke", "rgba(9, 163, 225, 0.45)")
        .attr("stroke-width", 3);

      for(const point of points){
        svg.append("text")
          .attr("x", point.x + 12)
          .attr("y", point.y - 15)
          .attr("text-anchor", "middle")
          .text(point.text);
      }
    },
    [temps, numberSize, marginBottom]
  );

  return <svg
    ref={d3ref}
    style={{
      width: "100%",
      height: "100%",
    }}
  />
}
import "./Screen.scss";
import { DataContext } from "../../contexts/DataContext";
import classNames from 'classnames';
import moment from "moment";
import { ReactComponent as AwnLogo } from '../../assets/awn-logo.svg';
import { ReactComponent as ToDashboard } from '../../assets/to-dashboard.svg';
import { ReactComponent as ToForecast } from '../../assets/to-forecast.svg';
import { ReactComponent as NewNotifications } from '../../assets/new-notifications.svg';
import { CardHeader } from "../../components/CardHeader/CardHeader";
import { Widget } from "../../widgets/Widget/Widget";
import { clone, path } from "ramda";
import React, { useState } from 'react';
import {Forecast} from "../Forecast/Forecast";
import {getShortMeridian, retrieveBackgroundImageUrl} from "../../utils";
import {useDynamicSize} from "../../hooks/useDynamicSize";
import {Forecast2} from "../Forecast2/Forecast2";
import { widgetUsesDefaultLocation } from "../../ambient";

const getSize = (layout, i) => {
  if (layout === 0) return 'medium-card';
  if (layout === 1) return [0, 5].includes(i) ? 'large-card' : 'small-card';
  if (layout === 2) return i === 2 ? 'large-card' : 'small-card';
  if (layout === 3) {
    if ([0,2].includes(i)) return 'long-card'
    if (i === 1) return 'large-card'
    return 'small-card'
  }
}

const widgetMaxNumber = [6, 6, 7, 7];

export function Screen({data, screenId}) {
  const elementRef = React.useRef(null);
  const meridian = getShortMeridian(moment().tz(data.tz));
  const [userData, setUserData] = React.useState(null);
  const [forecastData, setForecastData] = React.useState(null);
  const [ref, fontSize] = useDynamicSize(data.forecast?.currently?.summary || '', {
    textAlign: "center",
    wordBreak: "break-word",
    fontFamily: "Montserrat",
    fontSize: "35px",
    fontWeight: "bold"
  });

  const [pageLoaded, setPageLoaded] = useState(false);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const backgroundImageUrl = retrieveBackgroundImageUrl(screenId, data);
  React.useEffect(() => {
    setUserData(data.user);
    setForecastData(data.forecast);
    const onPageLoad = () => {
      setPageLoaded(true);
    };

    if (backgroundImageUrl) {
      const img = new Image();
      img.src = backgroundImageUrl;
      img.onload = () => {
        setBackgroundLoaded(true);
      };
    } else {
      setTimeout(() => {
        setBackgroundLoaded(true);
      }, 200);

    }

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
    }
    // Remove the event listener when component unmounts
    return () => {
      setPageLoaded(false);
      if (elementRef.current) {
        elementRef.current.classList.remove('page-finished-loading-marker');
      }
    };
  }, [data.user, data.forecast, backgroundImageUrl]);

  let content;
  let classes;
  let now;
  if(screenId === 2){
    if(data.forecastLayout){
      classes = classNames({
        'weather-screen': true, 'page-finished-loading-marker': pageLoaded},
        `background-forecast`
      )
      content = <DataContext.Provider
        value={{ userData }}
      >
        <Forecast
          user={data.user}
          forecast={data.forecast}
          data={data}
        />
      </DataContext.Provider>;
      now = moment().tz(data.tz).format("dddd, MMMM D");
    } else {
      classes = classNames({
          'weather-screen': true, 'page-finished-loading-marker': pageLoaded},
        `background-forecast`
      )
      content = <DataContext.Provider
        value={{ userData }}
      >
        <Forecast2
          user={data.user}
          forecast={data.forecast}
          data={data}
        />
      </DataContext.Provider>;
      now = moment().tz(data.tz).format("dddd, MMMM D");
    }
  } else {
    if(data.user.settings.hour24 && data.user.settings.hour24.unit === 1){
      now = moment().tz(data.tz).format("dddd, MMMM D  •  H:mm");
    } else {
      now = moment().tz(data.tz).format("dddd, MMMM D  •  h:mm")+meridian;
    }
    classes = classNames({
        'weather-screen': true, 'page-finished-loading-marker': pageLoaded & backgroundLoaded},
      `background-${path(['forecast', 'currently', 'icon'], data)}`
    );
    let widgetQuantity = 0;
    const widgetList = [];
    for(let i = 0; i < data.widgets.length; i++){
      const widget = data.widgets[i];
      if(widgetQuantity >= widgetMaxNumber[data.layout]) break;
      const device = data.devices.find(device => device.macAddress === widget.device);
      const size = getSize(data.layout, i);
      widgetList.push(
        <DataContext.Provider
          value={{ data, userData, forecastData, tz: data.tz }}
          key={widget.type + widget.device}
        >
          <div
            key={data.layout + '-' + i}
            className={classNames('metric-card', widget.type, 'device-widget', size)}
          >
            <CardHeader
              title={widget.title}
              subtitle={widgetUsesDefaultLocation(widget.type) ? data.forecast.location : device?.location}
            />
            <Widget type={widget.type} device={device} size={size}/>
          </div>
        </DataContext.Provider>
      );
      widgetQuantity ++;
    }
    content = <div className={classNames('widgets', `layout-${data.layout}`)}>
      {widgetList}
    </div>;
  }


  return <div ref={elementRef} className={classes} style={{
    backgroundImage: backgroundLoaded ? `url(${backgroundImageUrl})` : 'none',
    backgroundRepeat: 'no-repeat'
  }}>
    {data.layout === 2 && (screenId < 2 || !screenId) ? <div className="current-update" ref={ref}>
        <span style={{fontSize: fontSize}}>
          {data.forecast?.currently?.summary}
        </span>
    </div> : ''}
    {content}
    <div>
      <AwnLogo/>
      <span>{now}</span>
      {data.newNotifications ? <NewNotifications/> : null}
      {screenId === 2 ? <ToDashboard/> : <ToForecast/>}
    </div>
  </div>
}

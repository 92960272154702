import React from 'react';
import { dataHas } from '../../ambient'
import FormattedDataPoint from '../../components/FormattedDataPoint/FormattedDataPoint'
import "./EvapoTransWidget.scss"
import { CardFooter } from '../../components/CardFooter/CardFooter';
import {useWidth} from "../../hooks/useWidth";

export default function EvapoTransWidget({currentDevice, size}) {
  const [width, ref, mainFontStyle, sideStyle] = useWidth(size);
  const { lastData } = currentDevice
  const currentDataHas = dataHas(lastData)
  const mains = []

  if (currentDataHas(['etos'])) {
    mains.push(
      <div key="etos">
        <div className='label'>ETos</div>
        <div className='big-value'>
          <FormattedDataPoint
            value={currentDevice.lastData.etos}
            row={currentDevice.lastData}
            className='big-number'
            type={'etos'}
            suff=" "
            fontStyles={{
              mainStyle: mainFontStyle,
              suffixStyle: sideStyle,
            }}
            availableWidth={width * 0.45}
            roundDecimals={true}
          />
        </div>
      </div>
    )
  }  else {
    mains.push(
      <div>
         <div className='label'>ETos</div>
        <div className='big-value'>
          <div className="big-number">- -</div> 
        </div>
      </div>
    )
  }
  if (currentDataHas(['etrs'])) {
    mains.push(
      <div key="etrs">
      <div className='label'>ETrs</div>
        <div className='big-value'>
          <FormattedDataPoint
            value={currentDevice.lastData.etrs}
            row={currentDevice.lastData}
            className='big-number'
            type={'etrs'}
            fontStyles={{
              mainStyle: mainFontStyle,
              suffixStyle: sideStyle,
            }}
            availableWidth={width * 0.45}
            roundDecimals={true}
          />
        </div>
      </div>
    )
  } else {
    mains.push(
      <div>
        <div className='label'>ETrs</div>
      <div className='big-value'>
        <div className="big-number">- -</div> 
      </div>
    </div>
    )
  }

  return (
    <>
      <div className='metric-card-body etos' ref={ref}>
        <div className="list-body">
          {mains}
        </div>
      </div>
      <CardFooter
        values={[{name: "in/day", value: ""}]}
        size={size}
      />
    </>
  );
};

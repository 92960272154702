import "./ForecastWidget.scss";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import {range} from 'ramda'
import {useContext} from "react";
import moment from "moment";
import classNames from "classnames";
import {DataContext} from "../../contexts/DataContext";
import {getShortMeridian} from "../../utils";

export function ForecastWidget() {
  const globalState = useContext(DataContext);
  const forecast = globalState.forecastData;
  const user = globalState.userData;

  if (!forecast || !user) {
    return (
      <div className='forecast-widget'>
        <div className='outer-wrap'>
          <div>Loading...</div>
        </div>
      </div>
    )
  }

  const {hourly = {data: []}, currently = {}} = forecast;

  const hoursToShow = hourly.data ? hourly.data.slice(0, 9) : [];

  const todayTime = hoursToShow[0] ?  moment(hoursToShow[0].time * 1000).startOf('day') : '';
  const getAlt = (time) => {
    const thisTime = moment(time * 1000)
    if (thisTime.dayOfYear() === todayTime.dayOfYear()) {
      return
    }
    if (Math.floor(moment.duration(thisTime.diff(todayTime)).asDays()) > 1) {
      return
    }
    return 'alt'
  }
  return (
    <div className='forecast-widget'>
      <div className='outer-wrap'>
        { hoursToShow.length ? range(0, hoursToShow.length).map(i => {
          const hour = hoursToShow[i]
          console.log(hour)
          let title = 'Now'
          const m = moment(hour.time * 1000).tz(forecast?.tz)
          if (i > 0) {
            if (user.settings.hour24 && user.settings.hour24.unit === 1) {
              title = m.format('H');
            } else {
              title = m.format('h') + getShortMeridian(m);
            }
          }
          if (m.hour() === 0) {
            title = m.format('M/D')
          }
          return (
            <div key={`hour-${i}`} className={classNames('hour', getAlt(hour.time))}>
              <div className='title'>{title}</div>
              <div className={classNames('weather-icon', hour.icon)}/>
              <FormattedDataPoint type='tempf' value={hour.temperature} valueTransform={Math.round} suff=" "/>
              <div className={classNames('precip', hour.icon)}>
              </div>
            </div>
          )
        }) : 
        <div className="no-data">
            No data to show
        </div>}
      </div>
    </div>
  )
}
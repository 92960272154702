import "./Wind.scss";
import {CardFooter} from "../../components/CardFooter/CardFooter";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import {useWidth} from "../../hooks/useWidth";

export function Wind ({currentDevice, size}) {
  const [width, ref, , , footerStyle] = useWidth(size);
  const footerValues = [
    {
      name: "From",
      value: <FormattedDataPoint
        value={currentDevice.lastData.winddir}
        row={currentDevice.lastData} type='winddir' suff=" "
        fontStyles={{
          mainStyle: footerStyle,
          suffixStyle: footerStyle,
        }}
        availableWidth={width / 3}
        roundDecimals={true}
      />
    }
  ];
  if(currentDevice.lastData.maxdailygust !== undefined){
    footerValues.push(
      {
        name: "Today’s Peak",
        value: <FormattedDataPoint
          value={currentDevice.lastData.maxdailygust}
          row={currentDevice.lastData}
          type='maxdailygust'
          suff=" "
          fontStyles={{
            mainStyle: footerStyle,
            suffixStyle: footerStyle,
          }}
          availableWidth={width / 3}
          roundDecimals={true}
        />
      }
    );
  }
  if(currentDevice.lastData.windgustmph !== undefined){
    footerValues.push(
      {
        name: "Gusts",
        value: <FormattedDataPoint
          value={currentDevice.lastData.windgustmph}
          row={currentDevice.lastData}
          type='windgustmph'
          suff=" "
          fontStyles={{
            mainStyle: footerStyle,
            suffixStyle: footerStyle,
          }}
          availableWidth={width / 3}
          roundDecimals={true}
        />
      }
    );
  }

  return (
    <>
      <div className='metric-card-body' ref={ref}>
        <div className='big-value'>
          <FormattedDataPoint
            value={currentDevice.lastData.windspeedmph}
            row={currentDevice.lastData}
            className='big-number'
            type='windspeedmph'
            center={true}
          />
        </div>
      </div>
      <CardFooter
        values={footerValues}
        size={size}
      />
    </>
  )
}
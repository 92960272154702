import React, { PureComponent } from 'react'
import { convertUnitForUser } from '../../ambient'
import PropTypes from 'prop-types'
import { path, map, pipe } from 'ramda'
import PoleGraph from './PoleGraph'
import { DataContext } from '../../contexts/DataContext'
import "./HighLowGraphs.scss"

export default class HighLowGraphs extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object,
    format: PropTypes.func
  }

  _summaryLines () {
    const { currentDevice } = this.props
    const type = 'tempf'
    const user = this.context.userData

    const convert = convertUnitForUser(user, type)
    const retInfo = currentDevice.lastData.outdoorStats
    if(retInfo){
      const statsMax = Math.max(...map(pipe(path(['stats', 'max']), convert), retInfo))
      const statsMin = Math.min(...map(pipe(path(['stats', 'min']), convert), retInfo))
      return retInfo.map(info => {
        return <PoleGraph
          min={convert(info.stats.min)}
          max={convert(info.stats.max)}
          avg={convert(info.stats.sum / info.stats.count)}
          statsMin={statsMin}
          statsMax={statsMax}
          label={info.label}
          key={type + info.label}
        />
      })
    }
    return  <div className='metric-card-body'>
        <div className='big-value'>
          <div className='big-number'>- -</div>
        </div>
    </div>
    
  }

  render () {
    return (
      <div className='device-high-low-graphs'>
        {this._summaryLines()}
      </div>
    )
  }
}

HighLowGraphs.contextType = DataContext
HighLowGraphs.displayName = 'HighLowGraphs'

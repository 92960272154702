import "./Pressure.scss";
import { ReactComponent as ArrowDownBlue } from '../../ambient/assets/arrow-down-blue.svg';
import { ReactComponent as ArrowUpBlue } from '../../ambient/assets/arrow-up-blue.svg';
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";

const min = 27.5;
const max = 32.5;
export function Footer({device}) {
  const pressure = device.lastData.baromrelin;
  const diffOneHourAgo = device.lastData.baromrelinHourAgo - device.lastData.baromrelin;
  const absValueOfDiff = Math.abs(diffOneHourAgo);

  let pos = 0;
  if(pressure > max){
    pos = 100;
  }
  else if(pressure > min){
    const total = max - min;
    const offset = pressure - min;
    pos = 100 * offset / total;
  }

  let arrowIcon;
  if (diffOneHourAgo > 0) {
    arrowIcon =  <ArrowUpBlue/>;
  }
  else{
    arrowIcon = <ArrowDownBlue/>;
  }
  return <div className="pressure-footer">
    <div>
      <div>
        <div style={{left: `calc(${pos}% - 8px)`}}/>
      </div>
    </div>
    <div>
      {arrowIcon}
      <FormattedDataPoint value={absValueOfDiff} type="baromrelin"/>
    </div>
  </div>
}
import moment from "moment";
import classNames from "classnames";
import React from "react";
import { ReactComponent as WaterDrop } from '../../assets/water-drop.svg';
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";

const weekDays = {
  Sunday: "Sun",
  Monday: "Mon",
  Tuesday: "Tue",
  Wednesday: "Wed",
  Thursday: "Thu",
  Friday: "Fri",
  Saturday: "Sat",
}
export const Dailies = ({ days = []}) => {
  if (days.length === 0) {
    return <div className="no-forecast daily weather-table" >
      <div className="no-forecast-text">Daily forecast unavailable</div>
    </div>
  } 
  return <div className="dailies">
    {days.map(day => {
      return <div key={day.time}>
        <div>
          <div>
            <div className="week-day">{weekDays[moment(day.time * 1000).format('dddd')]}</div>
            <div className="month-day">{moment(day.time * 1000).format('MM/DD')}</div>
            <div className="rain-prob"><WaterDrop/> {parseInt(day.precipProbability * 100, 10)}%</div>
          </div>
          <div>
            <div className={classNames('weather-icon', day.icon)}/>
          </div>
        </div>
        <div className="min-max">
          <FormattedDataPoint type='tempf' className='max' value={day.temperatureMax} suff="°" valueTransform={Math.round} />
          / <FormattedDataPoint type='tempf' className='min' value={day.temperatureMin} suff="°" valueTransform={Math.round} />
        </div>
      </div>
    })}
  </div>;
}

import { ReactComponent as Moon0 } from './assets/sun-moon/moons-v2/moon-0.inline.svg'
import { ReactComponent as Moon1 } from './assets/sun-moon/moons-v2/moon-1.inline.svg'
import { ReactComponent as Moon2 } from './assets/sun-moon/moons-v2/moon-2.inline.svg'
import { ReactComponent as Moon3 } from './assets/sun-moon/moons-v2/moon-3.inline.svg'
import { ReactComponent as Moon4 } from './assets/sun-moon/moons-v2/moon-4.inline.svg'
import { ReactComponent as Moon5 } from './assets/sun-moon/moons-v2/moon-5.inline.svg'
import { ReactComponent as Moon6 } from './assets/sun-moon/moons-v2/moon-6.inline.svg'
import { ReactComponent as Moon7 } from './assets/sun-moon/moons-v2/moon-7.inline.svg'
import { ReactComponent as Moon8 } from './assets/sun-moon/moons-v2/moon-8.inline.svg'
import { ReactComponent as Moon9 } from './assets/sun-moon/moons-v2/moon-9.inline.svg'
import { ReactComponent as Moon10 } from './assets/sun-moon/moons-v2/moon-10.inline.svg'
import { ReactComponent as Moon11 } from './assets/sun-moon/moons-v2/moon-11.inline.svg'
import { ReactComponent as Moon12 } from './assets/sun-moon/moons-v2/moon-12.inline.svg'
import { ReactComponent as Moon13 } from './assets/sun-moon/moons-v2/moon-13.inline.svg'
import { ReactComponent as Moon14 } from './assets/sun-moon/moons-v2/moon-14.inline.svg'
import { ReactComponent as Moon15 } from './assets/sun-moon/moons-v2/moon-15.inline.svg'

export const moons = {
  v2: [
    Moon0,
    Moon1,
    Moon2,
    Moon3,
    Moon4,
    Moon5,
    Moon6,
    Moon7,
    Moon8,
    Moon9,
    Moon10,
    Moon11,
    Moon12,
    Moon13,
    Moon14,
    Moon15
  ]
}

// takes lune phase object
export const getMoonI = phase => {
  if (!phase) return 0
  const percent = phase.illuminated
  const totalMoons = 9
  const totalMoonIs = totalMoons - 1
  // waning
  if (phase.phase >= 0.5) {
    const i = totalMoonIs + Math.round(totalMoonIs - percent * totalMoonIs)
    return i >= 2 * totalMoonIs ? 0 : i
  }
  return Math.round(percent * totalMoonIs)
}

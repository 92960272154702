import "./WidgetGallery.scss";
import { useState } from "react"
import data from "./data.json";
import classNames from "classnames";
import {CardHeader} from "../../components/CardHeader/CardHeader";
import {Widget} from "../Widget/Widget";
import { DataContext } from "../../contexts/DataContext";

export function SingleWidgetSandbox () {
    const [theme, setTheme] = useState("light");
    const onOptionChange = e => {
        setTheme(e.target.value)
    }

    let className = "widget-gallery";
    if(theme === "dark"){
        className+= " dark";
    }
    else{
        className+= " light";
    }

    return <div className={className}>
        <input
            type="radio"
            name="theme"
            value="light"
            id="light"
            checked={theme === "light"}
            onChange={onOptionChange}
        />
        <label htmlFor="light">Light</label>

        <input
            type="radio"
            name="theme"
            value="dark"
            id="dark"
            checked={theme === "dark"}
            onChange={onOptionChange}
        />
        <label htmlFor="dark">Dark</label>

        <div className="row">
            {data.widgets.map((widget, i) => {
                const device = data.devices.find(device => device.macAddress === widget.device);
                return (
                  <DataContext.Provider
                    value={{ userData: data.user, forecastData: data.forecast, size: "small-card" }}
                    key={i}
                  ><div
                    key={data.layout + '-' + i}
                    className={classNames('metric-card', widget.type, 'device-widget', "small-card")}
                  >
                      <CardHeader title={widget.title} subtitle={device.location}/>
                      <Widget type={widget.type} device={device} size='small-card'/>
                  </div></DataContext.Provider>
                )
            })}
        </div>
        <div className="row">
            {data.widgets.map((widget, i) => {
                const device = data.devices.find(device => device.macAddress === widget.device);
                return (
                  <DataContext.Provider
                    value={{ userData: data.user, forecastData: data.forecast, size: "medium-card" }}
                    key={i}
                  ><div
                    key={data.layout + '-' + i}
                    className={classNames('metric-card', widget.type, 'device-widget', "medium-card")}
                  >
                      <CardHeader title={widget.title} subtitle={device.location}/>
                      <Widget type={widget.type} device={device} size='medium-card' />
                  </div></DataContext.Provider>
                )
            })}
        </div>
        <div className="row">
            {data.widgets.map((widget, i) => {
                const device = data.devices.find(device => device.macAddress === widget.device);
                return (
                  <DataContext.Provider
                    value={{ userData: data.user, forecastData: data.forecast, size: "large-card" }}
                    key={i}
                  ><div
                    key={data.layout + '-' + i}
                    className={classNames('metric-card', widget.type, 'device-widget', "large-card")}
                  >
                      <CardHeader title={widget.title} subtitle={device.location}/>
                      <Widget type={widget.type} device={device} size='large-card'/>
                  </div></DataContext.Provider>
                )
            })}
        </div>
    </div>
}

export const mainFontSmall = {
  fontFamily: "Bahnschrift, serif",
  fontWeight: "bold",
  fontStretch: "85%",
  fontStyle: "normal",
  fontSize: "88px"
};
export const mainFontMedium = {
  fontFamily: "Bahnschrift, serif",
  fontWeight: "bold",
  fontStretch: "85%",
  fontStyle: "normal",
  fontSize: "110px"
}
export const mainFontLarge = {
  fontFamily: "Bahnschrift, serif",
  fontWeight: "bold",
  fontStretch: "85%",
  fontStyle: "normal",
  fontSize: "130px"
}

export const sideFontSmall = {
  fontFamily: "Bahnschrift, serif",
  fontWeight: "bold",
  fontStretch: "85%",
  fontStyle: "normal",
  fontSize: "24px"
};
export const sideFontMedium = {
  fontFamily: "Bahnschrift, serif",
  fontWeight: "bold",
  fontStretch: "85%",
  fontStyle: "normal",
  fontSize: "36px"
}
export const sideFontLarge = {
  fontFamily: "Bahnschrift, serif",
  fontWeight: "bold",
  fontStretch: "85%",
  fontStyle: "normal",
  fontSize: "50px"
}

export const footerFontSmall = {
  fontFamily: "Montserrat, serif",
  fontWeight: "bold",
  fontStyle: "normal",
  fontSize: "21px"
};
export const footerFontMedium = {
  fontFamily: "Montserrat, serif",
  fontWeight: "bold",
  fontStyle: "normal",
  fontSize: "24px"
}
export const footerFontLarge = {
  fontFamily: "Montserrat, serif",
  fontWeight: "bold",
  fontStyle: "normal",
  fontSize: "28px"
}
import "./Pressure.scss";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import {Footer} from "./Footer";
import { ReactComponent as Arrow } from '../../assets/pressure-arrow.svg';
import {useWidth} from "../../hooks/useWidth";

const angleSections = [
  {
    rotation: -162,
    top: 269,
    left: 86
  },
  {
    rotation: -144,
    top: 206,
    left: 65
  },
  {
    rotation: -126,
    top: 136,
    left: 65
  },
  {
    rotation: -108,
    top: 70,
    left: 85
  },
  {
    rotation: -90,
    top: 17,
    left: 125
  },
  {
    rotation: -72,
    top: -23,
    left: 179
  },
  {
    rotation: -54,
    top: -46,
    left: 246
  },
  {
    rotation: -36,
    top: -46,
    left: 320
  },
  {
    rotation: -18,
    top: -23,
    left: 378
  },
  {
    rotation: 0,
    top: 17,
    left: 432
  },
  {
    rotation: 18,
    top: 72,
    left: 472
  },
  {
    rotation: 36,
    top: 136,
    left: 494
  },
  {
    rotation: 54,
    top: 206,
    left: 494
  },
  {
    rotation: 72,
    top: 269,
    left: 472
  }
];
const sectionWidth = 18;
const min = 27.5;
const max = 32.5;
export function Pressure ({currentDevice, size}) {
  const [width, ref, mainFontStyle, sideStyle] = useWidth(size);

  let pressure =  currentDevice.lastData.baromrelin ? currentDevice.lastData.baromrelin : null;
  if(pressure < min) pressure = min;
  if(pressure > max) pressure = max;

  const total = max - min;
  const totalAngle = angleSections.length * sectionWidth;
  const offset = pressure - min;
  const angle = totalAngle * offset / total;
  let section = Math.ceil(angle / sectionWidth);
  if(section === 0) section = 1;

  let availableWidth;
  let suffixStyle;
  switch (size){
    case "small-card":
      availableWidth = width;
      suffixStyle = sideStyle;
      break;
    case "medium-card":
      availableWidth = width;
      suffixStyle = sideStyle;
      break;
    default:
      availableWidth = 250;
  }

  return(
    <>
      <div className='metric-card-body' ref={ref}>
        <Arrow style={{
          transform: `rotate(${angleSections[section - 1].rotation}deg)`,
          top: angleSections[section - 1].top + "px",
          left: angleSections[section - 1].left + "px"
        }}/>
        <div className='big-value'>
          { currentDevice.lastData.baromrelin !== undefined ?
            <FormattedDataPoint
              row={currentDevice.lastData}
              className='big-number'
              type='baromrelin'
              center={true}
              fontStyles={{
                mainStyle: mainFontStyle,
                suffixStyle: suffixStyle,
              }}
              availableWidth={availableWidth}
              roundDecimals={true}
            /> :
            <div className="big-number">- -</div> }
        </div>
      </div>
      {
         currentDevice.lastData.baromrelin !== undefined ?
         <Footer device={currentDevice}/> : <div></div>
      }
    </>
  )
}
import "./Humidity.scss";
import {CardFooter} from "../../components/CardFooter/CardFooter";
import {ReactComponent as ArrowUp} from '../../ambient/assets/arrow-up-blue.svg';
import {ReactComponent as ArrowDown} from '../../ambient/assets/arrow-down-blue.svg';
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";

export function Humidity({currentDevice, size}) {
  const diffFromYesterday = currentDevice.lastData.humidityYesterday - currentDevice.lastData.humidity;
  const absValueOfDiff = Math.abs(diffFromYesterday);

  return (
    <>
      <div className='metric-card-body'>
        <div className='big-value'>
          <FormattedDataPoint value={currentDevice.lastData.humidity} row={currentDevice.lastData} className='big-number'
                              type='humidity' center={true}/>
        </div>
      </div>
      <CardFooter
        values={[{
          name: "From Yesterday",
          value: currentDevice.lastData.humidityYesterday !== undefined && absValueOfDiff !== undefined ? (
            diffFromYesterday < 0 ?
              <div className="humidity-footer-data">
                <div className="humidity-icon"><ArrowUp/></div>
                <FormattedDataPoint value={absValueOfDiff} row={currentDevice.lastData} type="humidity"/>
              </div> :
              <div className="humidity-footer-data">
                <div className="humidity-icon"><ArrowDown/></div>
                <FormattedDataPoint value={absValueOfDiff} row={currentDevice.lastData} type="humidity"/>
              </div>
          ) : <div className="big-number">- -</div>
        }]}
        size={size}
      />
    </>
  )
}
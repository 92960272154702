import React, {PureComponent} from 'react';
import {ambient} from '../../ambient/ambient-weather-common'
import PropTypes from 'prop-types';
import {includes} from 'ramda'
import {windDirLabel} from '../../ambient'
import classNames from 'classnames'
import moment from 'moment'
import {DataContext} from '../../contexts/DataContext';
import {countDecimals, getWidthOfText} from "../../utils";
import Children from 'react-children-utilities';

const {getMasterUnitParam, convertUnit, getUserUnitI, getSuff, isSomething} = ambient

/*dynamicWidth: {
  availableWidth: PropTypes.number,
    mainStyle: PropTypes.array,
    prefixStyle: PropTypes.array,
    suffixStyle: PropTypes.array,
}*/
class FormattedDataPoint extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    row: PropTypes.object,
    value: PropTypes.any,
    valueTransform: PropTypes.func,
    suff: PropTypes.string,
    after: PropTypes.string,
    before: PropTypes.string,
    skipConvert: PropTypes.bool,
    skipWindLabel: PropTypes.bool,
    className: PropTypes.string,
    center: PropTypes.bool,
    fontStyles: PropTypes.object,
    availableWidth: PropTypes.number,
    roundDecimals: PropTypes.bool
  }

  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  render() {
    const {
      valueTransform,
      before,
      after,
      suff,
      value,
      row,
      type,
      skipConvert,
      skipWindLabel,
      className,
      center,
      availableWidth,
      fontStyles,
      roundDecimals
    } = this.props;

    const user = this.context.userData;

    let val = value
    if (!isSomething(val)) {
      if (!row) {
        return <span/>
      }
      val = row[type]

    }
    let skipSuff = false
    let suffInternalOverride = false
    const unitParam = getMasterUnitParam(type)
    const userUnitI = getUserUnitI(type, user)

    if (!isSomething(val)) {
      val = '- -'
      skipSuff = true
      // convert degress to compass
    } else if (includes(type, ['winddir', 'windgustdir', 'winddir_avg2m', 'winddir_avg10m']) && !skipWindLabel) {
      skipSuff = true
      val = windDirLabel(val)
    } else if (type === 'dateutc') {
      skipSuff = true
      let format = 'MMM D h:mma'
      let dateutc = val
      const d = moment(dateutc, 'x')
      val = d.format(format)
    } else if (unitParam) {
      if (!skipConvert) {
        val = convertUnit(unitParam, userUnitI, val)
      }
      if (userUnitI > 0) {
        const origSuff = getSuff(unitParam)
        suffInternalOverride = origSuff.replace(ambient.DATA_SPEC[unitParam].units[0], ambient.DATA_SPEC[unitParam].units[userUnitI])
      }
    }

    let suffElem = ''
    if (suff || suffInternalOverride || (!skipSuff && getSuff(type))) {
      let suffContent = suff || suffInternalOverride || getSuff(type)
      const exponentRegEx = /\^(\d)/
      if (exponentRegEx.test(suffContent)) {
        const match = suffContent.match(exponentRegEx)
        suffContent = <span>{suffContent.replace(exponentRegEx, '')}<sup>{match[1]}</sup></span>
      }
      suffElem = <span className="suff">{suffContent}</span>
    }
    let afterElem = ''
    if (after) {
      afterElem = <span className="after">{after}</span>
    }
    let beforeElem = ''
    if (before) {
      beforeElem = <span className="before">{before}</span>
    }
    if (valueTransform) {
      val = valueTransform(val)
    }

    let theClassName = ''
    if (className) {
      theClassName = classNames(className)
    }

    const cls = 'device-formatted-data-point fdp ' + type + ' ' + theClassName

    let counterSuffix = "";
    let counterPrefix = "";
    if (center) {
      counterSuffix = suffElem;
      counterPrefix = beforeElem;
    }

    let mainStyle = {};
    let prefixStyle = {};
    let suffixStyle = {};
    if (availableWidth && fontStyles) {
      const mainWidth = getWidthOfText(Children.onlyText(val), fontStyles.mainStyle);
      const prefixWidth = fontStyles.prefixStyle ?
        getWidthOfText(Children.onlyText(beforeElem), fontStyles.prefixStyle) : 0;
      const suffixWidth = fontStyles.suffixStyle ?
        getWidthOfText(" " + Children.onlyText(suffElem), fontStyles.suffixStyle) : 0;

      const suffSide = center ? suffixWidth * 2 : suffixWidth;
      const preSide = center ? prefixWidth * 2 : prefixWidth;

      if (mainWidth + preSide + suffSide > availableWidth) {
        let textFits = false;
        let shorted = val;
        if (!isNaN(val)) {
          const decimalCount = countDecimals(val);
          for (let i = 0; i < decimalCount; i++) {
            if (i === decimalCount - 1) {
              if(roundDecimals){
                shorted = Math.round(Number(shorted)).toString();
              }
              else{
                shorted = Math.trunc(Number(shorted)).toString();
              }
            } else {
              if(shorted.charAt(shorted.length - 1) === "0"){
                shorted = shorted.substring(0, shorted.length - 1);
              }
              else{
                const pow = Math.pow(10, decimalCount - i - 1);
                if(roundDecimals){
                  shorted = Math.round((Number(shorted) + Number.EPSILON) * pow) / pow;
                }
                else{
                  shorted = Math.trunc((Number(shorted) + Number.EPSILON) * pow) / pow;
                }
                if(Number.isInteger(shorted)) shorted += ".";
                const remainingDecimals = countDecimals(shorted);
                for(let j = 0; j < decimalCount - (i + 1) - remainingDecimals; j++){
                  shorted += "0";
                }
              }
            }

            const newWidth = getWidthOfText(shorted.toString(), fontStyles.mainStyle);
            if (newWidth + preSide + suffSide <= availableWidth) {
              textFits = true;
              break;
            }
          }
        }
        if (!textFits) {
          shorted = shorted.toString();
          let currentFontSize = fontStyles.mainStyle.fontSize.match(/\d+/)[0];
          let currentSuffixFontSize = fontStyles.suffixStyle ? fontStyles.suffixStyle.fontSize.match(/\d+/)[0] : null;
          let currentPrefixFontSize = fontStyles.prefixStyle ? fontStyles.prefixStyle.fontSize.match(/\d+/)[0] : null;
          const unit = fontStyles.mainStyle.fontSize.replace(/[0-9.]/g, "");

          const decrement = currentFontSize / 20;
          const minimumSize = currentFontSize * 0.4;
          while (currentFontSize > minimumSize) {
            currentFontSize = currentFontSize - decrement;
            const newWidth = getWidthOfText(shorted, {
              ...fontStyles.mainStyle,
              fontSize: currentFontSize + unit
            });
            mainStyle = {
              fontSize: currentFontSize + unit
            }

            let newSuffixWidth = suffixWidth;
            if (currentSuffixFontSize && currentFontSize <= currentSuffixFontSize) {
              currentSuffixFontSize = currentFontSize;
              newSuffixWidth = getWidthOfText(" " + Children.onlyText(suffElem), {
                ...fontStyles.suffixStyle,
                fontSize: currentSuffixFontSize + unit
              });
              suffixStyle = {
                fontSize: currentSuffixFontSize + unit
              }
            }

            let newPrefixWidth = prefixWidth;
            if (currentPrefixFontSize && currentFontSize <= currentPrefixFontSize) {
              currentPrefixFontSize = currentFontSize;
              newPrefixWidth = getWidthOfText(" " + Children.onlyText(suffElem), {
                ...fontStyles.prefixStyle,
                fontSize: currentPrefixFontSize + unit
              });
              suffixStyle = {
                fontSize: currentPrefixFontSize + unit
              }
            }

            const suffSide = center ? newSuffixWidth * 2 : newSuffixWidth;
            const preSide = center ? newPrefixWidth * 2 : newPrefixWidth;
            if (newWidth + preSide + suffSide <= availableWidth - 10) {
              break;
            }
          }
        }

        val = shorted;
      }
    }

    return (
      <span className={cls} ref={this.container}>
        <span className="counter-suffix" style={suffixStyle}>{counterSuffix}</span>
        <span style={prefixStyle}>{beforeElem}</span>
        <span className="fdp-val" style={mainStyle}>{val}</span>
        <span style={suffixStyle}>{suffElem}</span>
        {afterElem}
        <span className="counter-prefix" style={prefixStyle}>{counterPrefix}</span>
      </span>
    )
  }
}

export default FormattedDataPoint

FormattedDataPoint.contextType = DataContext

FormattedDataPoint.displayName = 'FormattedDataPoint'

import data from "./ambient/data.json";
import astro from "./ambient/astro";
import {getAstroValue} from "./ambient";
import {path} from "ramda";
import moment from "moment";
import cloudyDay from "./assets/backgrounds/Weather_Window_Cloudy_Day.png";
import cloudyNight from "./assets/backgrounds/Weather_Window_Cloudy_Night.png";
import frozenDay from "./assets/backgrounds/Weather_Window_Frozen_Day.png";
import frozenNight from "./assets/backgrounds/Weather_Window_Frozen_Night.png";
import partlyCloudyDay from "./assets/backgrounds/Weather_Window_Partly_Cloudy_Day.png";
import partlyCloudyNight from "./assets/backgrounds/Weather_Window_Partly_Cloudy_Night.png";
import genericLight from "./assets/backgrounds/Weather_Window_Generic_Light.png";
import genericDark from "./assets/backgrounds/Weather_Window_Generic_Dark.png";
import lightningDay from "./assets/backgrounds/Weather_Window_Lightning_Day.png";
import lightningNight from "./assets/backgrounds/Weather_Window_Lightning_Night.png";
import rainyDay from "./assets/backgrounds/Weather_Window_Rainy_Day.png";
import rainyNight from "./assets/backgrounds/Weather_Window_Rainy_Night.png";
import clearDay from "./assets/backgrounds/Weather_Window_Clear_Day.png";
import clearNight from "./assets/backgrounds/Weather_Window_Clear_Night.png";

export const getShortMeridian = (date) => {
  return date.format("a").substring(0, 1);
}
export const isDay = (date, lat, lon, tz) => {
  const getValue = getAstroValue({lat: lat, lon: lon, tz: tz});
  const sunrise = getValue(astro.solar.rise, Date.now());
  const sunset = getValue(astro.solar.set, Date.now());
  return date.isSameOrAfter(sunrise) && date.isBefore(sunset);
}
const isObjectJSON = function (obj) {
  return obj && typeof obj === 'object' && !Array.isArray(obj);
};
export const getWidthOfText = (text, styles) => {
  const element = document.createElement('span');
  if (isObjectJSON(styles)) {
    const styleKeys = Object.keys(styles);
    for (let i = 0, n = styleKeys.length; i < n; ++i) {
      element.style[styleKeys[i]] = styles[styleKeys[i]];
    }
  }
  element.style.display = 'inline-block';
  element.innerHTML = text;
  document.body.appendChild(element);
  let width = element.getBoundingClientRect().width;
  document.body.removeChild(element);

  return width;
};
export const getHeightOfParagraph = (text, fontStyle, width) => {
  const element = document.createElement('span');
  if (isObjectJSON(fontStyle)) {
    const styleKeys = Object.keys(fontStyle);
    for (let i = 0, n = styleKeys.length; i < n; ++i) {
      element.style[styleKeys[i]] = fontStyle[styleKeys[i]];
    }
  }
  element.style.display = 'inline-block';
  element.style.width = width;
  element.innerHTML = text;
  document.body.appendChild(element);
  const height = element.getBoundingClientRect().height;
  document.body.removeChild(element);
  return height;
};
export const countDecimals = (value) => {
  const numberVal = Number(value);
  if (!isNaN(numberVal)) {
    const parts = value.toString().split(".");
    if(parts.length > 1)
      return value.toString().split(".")[1].length || 0;
  }
  return 0;
}

export const getData = () => {
  return data;
}

export const resizeUntilFits = (val, fontStyle, availableWidth) => {
  let currentFontSize = fontStyle.fontSize.match(/\d+/)[0];
  const unit = fontStyle.fontSize.replace(/[0-9.]/g, "");
  const decrement = currentFontSize / 20;
  const minimumSize = currentFontSize * 0.4;
  let valSize = fontStyle.fontSize;
  while (currentFontSize > minimumSize) {
    const newWidth = getWidthOfText(val, {
      ...fontStyle,
      fontSize: currentFontSize + unit
    });
    valSize = currentFontSize + unit;

    if(newWidth <= availableWidth) break;

    currentFontSize = currentFontSize - decrement;
  }

  return valSize;
}


export const retrieveBackgroundImageUrl = (screenId, data) => {

  // forecast screen has no dynamic url, only dynamic background has
  if (screenId === 2 || data.theme !== "dynamic") {
    return null;
  }

  let backgroundImageUrl = null;
  const icon = path(['forecast', 'currently', 'icon'], data);
  const isLight = isDay(moment(), data.forecast.lat, data.forecast.lon, data.forecast.tz);

  switch (icon) {
    case 'cloudy':
      backgroundImageUrl = isLight ? cloudyDay : cloudyNight;
      break;
    case 'partly-cloudy-day':
    case 'part-sun':
    case 'partly-cloudy-night':
      backgroundImageUrl = isLight ? partlyCloudyDay : partlyCloudyNight;
      break;
    case 'hail':
    case 'flurries':
    case 'snow':
    case 'sleet':
      backgroundImageUrl = isLight ? frozenDay : frozenNight;
      break;
    case 'fog':
    case 'wind':
      backgroundImageUrl = isLight ? genericLight : genericDark;
      break;
    case 'lightning':
    case 'thunderstorm':
      backgroundImageUrl = isLight ? lightningDay : lightningNight;
      break;
    case 'rain':
    case 'sprinkles':
      backgroundImageUrl = isLight ? rainyDay : rainyNight;
      break;
    case 'clear':
    case 'clear-night':
      backgroundImageUrl = isLight ? clearDay : clearNight;
      break;
    default:
      backgroundImageUrl = isLight ? clearDay : clearNight;
  }

  return backgroundImageUrl;
};

export const toRadian = (d) => {
  return (Math.PI / 180.0) * d
}
export const makeId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const weekDays = {
  Sunday: "S",
  Monday: "M",
  Tuesday: "T",
  Wednesday: "W",
  Thursday: "Th",
  Friday: "F",
  Saturday: "Sa",
};

import {useContext} from "react";
import moment from "moment";
import classNames from "classnames";

import "./WeeklyForecastWidget.scss";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import { DataContext } from "../../contexts/DataContext";
import { weekDays } from '../../utils';

export function WeeklyForecastWidget() {
  const globalState = useContext(DataContext);
  const forecast = globalState.forecastData;
  const user = globalState.userData;

  if (!forecast || !user) {
    return (
      <div className='weekly-forecast-widget'>
        <div className='outer-wrap'>
          <div>Loading...</div>
        </div>
      </div>
    )
  }

  const {daily = {data: []}} = forecast;
  // take the daily forecast for the next 7 days
  const days = daily.data
      .filter(
          record => moment(record.time * 1000).tz(forecast.tz).startOf('day').valueOf() > moment().tz(forecast.tz).startOf('day').valueOf()
      )
      .slice(0, 7);
  if (forecast.currently) {
      days.unshift(forecast.currently);
  }

  return (
    <div className='weekly-forecast-widget'>
      <div className='outer-wrap'>
        { days.length ? days.map((day, i) => {
          let title = 'Today';
          if (i > 0) {
            title = weekDays[moment(day.time * 1000).format('dddd')];
          }

          let tempValue;
          let t = day.temperature || day.temperatureMax
          if (t) {
            tempValue = <FormattedDataPoint type='tempf' value={t} valueTransform={Math.round} suff="°"/>
          } else {
            tempValue = <div className="fdp">- -</div>
          }

          return (
            <div key={`day-${i}`} className='day'>
              <div className='title'>{title}</div>
              <div className={classNames('weather-icon', day.icon)}/>
              {tempValue}
              <div className={classNames('precip', day.icon)}>
              </div>
            </div>
          )
        }) : 
        <div className="no-data">
            No data to show
        </div>}
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import "./WebcamWidget.scss";
import {ReactComponent as ImageNotAvailable} from '../../assets/image-not-available.svg';

export const WebcamWidget = ({ currentDevice }) => {
  const [loaded, setLoaded] = useState(false);
  const [failed, setFailed] = useState(false);
  const imageUrl = currentDevice.webcam;

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {setLoaded(true); setFailed(false);}
    img.onerror = () => {setLoaded(true); setFailed(true);}
  }, [imageUrl]);

  if (!loaded) {
    return <div className='webcam-widget-loading'></div>;
  } else if (failed) {
    return <div className='image-not-available-container'>
      <ImageNotAvailable className='image-not-available'/>
      <div>No image available</div>
    </div>
  }

  return <img className='webcam-widget' src={imageUrl} alt="webcam image" />;
};

import "./CardFooter.scss";

export function CardFooter({values, size}) {
  let columns = [];
  if(values && values.length > 0){
    columns = values.map(item => {
      return <div className="footer-column" key={item.name}>
        <div>{item.name}</div>
        <div>
          {item.value}
        </div>
      </div>
    })
  }
  return <div className="metric-card-footer">
    {columns}
  </div>
}
import {useCallback, useState} from "react";
import {
  footerFontLarge,
  footerFontMedium,
  footerFontSmall,
  mainFontLarge,
  mainFontMedium,
  mainFontSmall, sideFontLarge,
  sideFontMedium,
  sideFontSmall
} from "../widgets/Widget/fontStyles";

export const useWidth = (size) => {
  const [width, setWidth] = useState(0);

  const ref = useCallback(node => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  let mainFontStyle;
  let sideStyle;
  let footerStyle;
  switch (size){
    case "small-card":
      mainFontStyle = mainFontSmall;
      sideStyle = sideFontSmall;
      footerStyle = footerFontSmall;
      break;
    case "medium-card":
      mainFontStyle = mainFontMedium;
      sideStyle = sideFontMedium;
      footerStyle = footerFontMedium;
      break;
    default:
      mainFontStyle = mainFontLarge;
      sideStyle = sideFontLarge;
      footerStyle = footerFontLarge;
  }

  return [width, ref, mainFontStyle, sideStyle, footerStyle];
}
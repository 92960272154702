import React, {PureComponent} from 'react';
import {isSomething, dataHas} from '../../ambient'
import PropTypes from 'prop-types';
import FormattedDataPoint from '../../components/FormattedDataPoint/FormattedDataPoint'
import "./LeafBinWidget.scss"
import {CardFooter} from '../../components/CardFooter/CardFooter';
import {path} from 'ramda';

// Binary Leaf Wetness
// Shows current state (Wet or Dry) and sum of minutes in the Wet state today
export default class LeafBinWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object.isRequired,
    size: PropTypes.string
  }

  render() {
    const {currentDevice, size} = this.props
    const {lastData} = currentDevice

    let widget = <div className="device-leaf-bin-widget hidden"/>
    const currentDataHas = dataHas(lastData)
    if (currentDataHas(['leafwet1x'])) {
      let from = ''
      let todaysLeafwetSum

      if (lastData.hl && lastData.hl.leafwetday) {
        todaysLeafwetSum = lastData.hl.leafwetday.s
      }

      if (isSomething(todaysLeafwetSum)) {
        from =
          <FormattedDataPoint value={path(['hl', 'leafwetday', 's'], lastData)} row={lastData} type={'leafwet1x'}/>
      }

      let currState = 'Dry'
      if (lastData.leafwet1x && (lastData.leafwet1x > 0)) {
        currState = 'Wet'
      }

      widget =
        <>
          <div className='metric-card-body'>
            <div className='big-value'>
              <div className='big-number'>
                {currState}
              </div>
            </div>
          </div>
          <CardFooter
            values={[{
              name: "Total wet min. today",
              value: from
            }]}
            size={size}
          />
        </>
    }
    return widget
  }
}

LeafBinWidget.displayName = 'LeafBinWidget'

import "./TempRH.scss";
import {ReactComponent as Temperature} from '../../ambient/assets/temperature.svg';
import {ReactComponent as Humidity} from '../../ambient/assets/humidity.svg';
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import {dataHas} from "../../ambient";
import classNames from "classnames";
import {useWidth} from "../../hooks/useWidth";
import { CardFooter } from "../../components/CardFooter/CardFooter";

export function TempRH({currentDevice, size, type}) {
  const [width, ref, mainFontStyle, sideStyle] = useWidth(size);
  const {lastData} = currentDevice
  const currentDataHas = dataHas(lastData)

  let tempKey = 'tempinf'
  let humidityKey = 'humidityin'
  let footer = null

  if (/sensor/.test(type)) {
    const sensor = type.replace('sensor', '')
    if (sensor) {
      tempKey = `temp${sensor}f`
      humidityKey = `humidity${sensor}`
    }
  } else if (type === 'outdoor-temp-hum') {
    tempKey = 'tempf'
    humidityKey = 'humidity'
    footer = (
      <CardFooter
        values={[
          {
            name: "Dew Point",
            value:<FormattedDataPoint
              value={lastData.dewPoint}
              row={lastData}
              type='dewPoint'
            />
          },
          {
            name: "Feels Like",
            value:<FormattedDataPoint
              value={lastData.feelsLike}
              row={lastData}
              type='feelsLike'
            />
          }
        ]}
        size={size}
      />
    )
  }

  const mains = []

  if (currentDataHas([tempKey])) {
    mains.push(
      <div key={1}>
        <div className='big-value'>
          <FormattedDataPoint
            value={currentDevice.lastData[tempKey]}
            row={currentDevice.lastData}
            className='big-number'
            type={tempKey}
            fontStyles={{
              mainStyle: mainFontStyle,
              suffixStyle: sideStyle,
            }}
            availableWidth={width * 0.6}
            roundDecimals={true}
          />
        </div>
        {size === 'large-card' ? <Temperature className="temprh-icon"/> : <div></div>}
      </div>
    )
  } else {
    mains.push(
      <div className='big-value'>
        <div className="big-number">- -</div>
      </div>
    )
  }

  if (currentDataHas([humidityKey])) {
    mains.push(
      <div key={2}>
        <div className='big-value'>
          <FormattedDataPoint
            value={currentDevice.lastData[humidityKey]}
            row={currentDevice.lastData}
            className='big-number'
            type={humidityKey}
            fontStyles={{
              mainStyle: mainFontStyle,
              suffixStyle: sideStyle,
            }}
            availableWidth={width * 0.4}
            roundDecimals={true}
          />

        </div>
        {size === 'large-card' ? <Humidity className="temprh-icon"/> : <div></div>}
      </div>
    )
  } else {
    mains.push(
      <div className='big-value' key={2}>
        <div className="big-number">- -</div>
      </div>
    )
  }

  return (
    <>
      <div className={classNames('metric-card-body', 'temp-card', 'temprh-tile')} ref={ref}>
        <div className="listBody">
          {mains}
        </div>
        {footer}
      </div>
    </>
  )
}
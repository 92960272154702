import { useEffect, createRef } from "react";

import * as d3 from "d3";

export const useD3 = (renderGraphicFn, dependencies) => {
  const ref = createRef();

  useEffect(() => {
    renderGraphicFn(d3.select(ref.current));
  }, [dependencies, ref, renderGraphicFn]);
  return ref;
};

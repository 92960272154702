import {useContext} from "react";
import moment from "moment";

import "./DailyForecastWidget.scss";
import { DataContext } from "../../contexts/DataContext";
import { Dailies } from "../../layouts/Forecast2/Dailies";

export function DailyForecastWidget() {
  const globalState = useContext(DataContext);
  const forecast = globalState.forecastData;
  const user = globalState.userData;

  if (!forecast || !user) {
    return (
      <div className='daily-forecast-widget'>
        <div className='outer-wrap'>
          <div>Loading...</div>
        </div>
      </div>
    )
  }

  const {daily = {data: []}} = forecast;
  const days = daily.data
      .filter(
          record => moment(record.time * 1000).tz(forecast.tz).startOf('day').valueOf() >= moment().tz(forecast.tz).startOf('day').valueOf()
      )
      .slice(0, 4);

  return (
    <div className='daily-forecast-widget'>
      <div className='outer-wrap'>
        { days.length ? <Dailies days={days}/> :
        <div className="no-data">
            No data to show
        </div>}
      </div>
    </div>
  )
}

import React from 'react';
import HighLowGraphs from './HighLowGraphs';
import "./TempWidget.scss";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import {useWidth} from "../../hooks/useWidth";

export const TempOutdoor = ({currentDevice, size}) => {
  const [width, ref] = useWidth(size);
  let footerStyle = {
    fontFamily: "Montserrat, serif",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "20px"
  };
  let dewOffset = 95;
  let feelsOffset = 88;
  if(size === "large-card"){
    footerStyle = {
      fontFamily: "Montserrat, serif",
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "28px"
    };
    dewOffset = 126;
    feelsOffset = 118;
  }

  return (
    <div className="device-temp-widget center-aligned">
      <div className="expanded-wrap">
        <HighLowGraphs
          currentDevice={currentDevice}
          type={'tempf'}
        />
      </div>
      <div className="outdoor-footer" ref={ref}>
        <div>
          <span>Dew Point</span> <span>
              <FormattedDataPoint
                value={currentDevice.lastData.dewPoint}
                row={currentDevice.lastData}
                type='dewPoint'
                suff='°'
                fontStyles={{
                  mainStyle: footerStyle,
                  suffixStyle: footerStyle,
                }}
                availableWidth={width / 2 - dewOffset}
                roundDecimals={true}
              />
            </span>
        </div>
        <div>
          <span>Feels Like</span> <span>
              <FormattedDataPoint
                value={currentDevice.lastData.feelsLike}
                row={currentDevice.lastData}
                type="feelsLike"
                fontStyles={{
                  mainStyle: footerStyle,
                  suffixStyle: footerStyle,
                }}
                availableWidth={width / 2 - feelsOffset}
                roundDecimals={true}
              />
          </span>
        </div>
      </div>
    </div>
  )
}

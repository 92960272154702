import React from 'react';
import classNames from 'classnames'
import {dataHas} from '../../ambient'
import FormattedDataPoint from '../../components/FormattedDataPoint/FormattedDataPoint'
import './SoilTension.scss'
import {useWidth} from "../../hooks/useWidth";

export const SoilTension = ({currentDevice, type = "soiltens1", size}) => {
  const [width, ref, mainFontStyle, sideStyle] = useWidth(size);
  const {lastData} = currentDevice
  const currentDataHas = dataHas(lastData)
  const sensor = type.replace('soiltens', '')
  const tempKey = `soiltemp${sensor}`
  const secondKey = `soiltens${sensor}`
  const mains = []

  if (currentDataHas([tempKey])) {
    mains.push(
      <div key={1}>
        <div className='big-value'>
          <FormattedDataPoint
            value={currentDevice.lastData[tempKey]}
            row={currentDevice.lastData}
            className='big-number'
            type={tempKey}
            fontStyles={{
              mainStyle: mainFontStyle,
              suffixStyle: sideStyle,
            }}
            availableWidth={width * 0.60}
            roundDecimals={true}
          />
        </div>
      </div>
    )
  } else {
    mains.push(
      <div className='big-value'>
        <div className="big-number">- -</div> 
      </div>
    )
  }
  if (currentDataHas([secondKey])) {
    mains.push(
      <div key={2}>
        <div className='big-value'>
          <FormattedDataPoint
            value={currentDevice.lastData[secondKey]}
            row={currentDevice.lastData}
            className='big-number'
            type={secondKey}
            fontStyles={{
              mainStyle: mainFontStyle,
              suffixStyle: sideStyle,
            }}
            availableWidth={width * 0.4}
            roundDecimals={true}
          />
        </div>
      </div>
    )
  } else {
    mains.push(
      <div className='big-value'>
        <div className="big-number">- -</div> 
      </div>
    )
  }

  return (
    <>
      <div className={classNames('metric-card-body', size === 'large-card' ? 'soiltension' : '')} ref={ref}>
        <div className="listBody">
          {mains}
        </div>
      </div>
    </>
  );
}

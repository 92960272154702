import moment from "moment";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import React from "react";
import classNames from "classnames";
import { ReactComponent as WaterDrop } from '../../assets/water-drop.svg';
import { ReactComponent as PercentDropWhite } from '../../assets/humidity-white.svg';
import {getShortMeridian} from "../../utils";
import {range} from "ramda";
import {TempCurve} from "./TempCurve";

export const Hourlies = ({hoursToShow = [], user, tz, today}) => {
  if (hoursToShow.length === 0) {
    return <div className="no-forecast daily weather-table" >
      <div className="no-forecast-text">Hourly forecast unavailable</div>
    </div>
  }
  const todayTime = moment(hoursToShow[0]?.time * 1000).startOf('day')
  const getAlt = (time) => {
    const thisTime = moment(time * 1000);
    if (thisTime.dayOfYear() === todayTime.dayOfYear()) {
      return;
    }
    if (Math.floor(moment.duration(thisTime.diff(todayTime)).asDays()) > 1) {
      return;
    }
    return 'alt';
  }

  let now;
  if(hoursToShow.length > 0){
    let hourText = 'Now';
    const m = moment(hoursToShow[0].time * 1000).tz(tz);
    if (m.hour() === 0) {
      hourText = m.format('M/D');
    }

    now = <>
      <div className="title">{hourText}</div>
      <div className="temp-icon">
        <FormattedDataPoint type='tempf' value={hoursToShow[0].temperature} suff="°" valueTransform={Math.round} />
        <div className={classNames('weather-icon', hoursToShow[0].icon)}/>
      </div>
      <div className="rain-prob">
        <WaterDrop className="small light"/>
        <PercentDropWhite className="small dark"/>
        <span>{parseInt(hoursToShow[0].precipProbability * 100, 10)}%</span>
      </div>
      <div className="hl-temp">
        H: <FormattedDataPoint type='tempf' value={today?.temperatureMax} suff="°" valueTransform={Math.round} />
        / L: <FormattedDataPoint type='tempf' value={today?.temperatureMin} suff="°" valueTransform={Math.round} />
      </div>
    </>;
  }

  return <div className="hourlies">
    <div className="now">{now}</div>
    <div className="the-rest">
      <div className="row">
        {hoursToShow.length ? range(1, hoursToShow.length).map(i => {
          let hourText;
          const m = moment(hoursToShow[i].time * 1000).tz(tz);
          if(user.settings.hour24 && user.settings.hour24.unit === 1){
            hourText = m.format('H');
          }
          else{
            hourText = m.format('h') + getShortMeridian(m);
          }
          if (m.hour() === 0) {
            hourText = m.format('M/D');
          }
          return (
            <div key={`hour-${i}`} className={classNames('title', getAlt(hoursToShow[i].time), "cell")}>
              {hourText}
            </div>
          )
        }): null}
      </div>
      <div className="row">
        {hoursToShow.length ? range(1, hoursToShow.length).map(i => {
          return (
            <div className="cell icon" key={`hour-${i}`}>
              <div className={classNames('weather-icon', hoursToShow[i].icon)}/>
            </div>
          )
        }): null}
      </div>
      <div className="row">
        {hoursToShow.length ? range(1, hoursToShow.length).map(i => {
          return (
            <div className={classNames("cell", "rain-prob", "small")} key={`hour-${i}`}>
              <WaterDrop/> <span>{parseInt(hoursToShow[i].precipProbability * 100, 10)}%</span>
            </div>
          )
        }): null}
      </div>
      <div className="row graph">
        {hoursToShow.length ?
          <TempCurve
            temps={range(1, hoursToShow.length).map(i => hoursToShow[i].temperature)}
            numberSize={60}
            marginBottom={20}
          /> :
          null
        }
      </div>
    </div>
  </div>;
}
import "./WindRose.scss";
import React from "react";
import {RoseGraph} from "./RoseGraph";
import {CardFooter} from "../../components/CardFooter/CardFooter";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";

const footerStyles = {
  "small-card":  {
    fontFamily: "Montserrat, serif",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "18px"
  },
  "medium-card": {
    fontFamily: "Montserrat, serif",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "20px"
  },
  "large-card": {
    fontFamily: "Montserrat, serif",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "28px"
  }
};
const footerWidths = {
  "small-card":  {
    speed: 100,
    from: 55,
    gusts: 55,
  },
  "medium-card": {
    speed: 140,
    from: 60,
    gusts: 55,
  },
  "large-card": {
    speed: 180,
    from: 85,
    gusts: 65,
  }
};

export const WindRose = ({currentDevice, size}) => {
  const footerValues = [
    {
      name: "Speed",
      value: <FormattedDataPoint
        value={currentDevice.lastData.windspeedmph}
        row={currentDevice.lastData}
        type='windspeedmph'
        fontStyles={{
          mainStyle: footerStyles[size],
          suffixStyle: footerStyles[size],
        }}
        availableWidth={footerWidths[size].speed}
        roundDecimals={true}
      />
    }
  ];
  if(size === "medium-card" || size === "large-card") {
    footerValues.push(
      {
        name: "Gusts",
        value: <FormattedDataPoint
          value={currentDevice.lastData.windgustmph}
          row={currentDevice.lastData}
          type='windgustmph'
          suff=" "
          fontStyles={{
            mainStyle: footerStyles[size],
            suffixStyle: footerStyles[size],
          }}
          availableWidth={footerWidths[size].gusts}
          roundDecimals={true}
        />
      }
    );
    footerValues.push(
      {
        name: "From",
        value: <FormattedDataPoint
          value={currentDevice.lastData.winddir}
          row={currentDevice.lastData}
          type='winddir'
          suff=" "
          fontStyles={{
            mainStyle: footerStyles[size],
            suffixStyle: footerStyles[size],
          }}
          availableWidth={footerWidths[size].from}
          roundDecimals={true}
        />
      }
    );
  }
  if(size === "small-card") {
    footerValues.push(
      {
        name: "Gusts",
        value: <FormattedDataPoint
          value={currentDevice.lastData.windgustmph}
          row={currentDevice.lastData}
          type='windgustmph'
          suff=" "
          fontStyles={{
            mainStyle: footerStyles[size],
            suffixStyle: footerStyles[size],
          }}
          availableWidth={footerWidths[size].gusts}
          roundDecimals={true}
        />
      }
    );
  }

  return <>
    <div className="metric-card-body">
      <RoseGraph currentDevice={currentDevice} size={size} />
    </div>
    <CardFooter
      values={footerValues}
      size={size}
    />
  </>;
}
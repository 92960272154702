import "./SolarRadiation.scss";
import {CardFooter} from "../../components/CardFooter/CardFooter";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import { path } from 'ramda';
import {useWidth} from "../../hooks/useWidth";

export function SolarRadiation ({ currentDevice, size }) {
  const [width, ref, mainFontStyle, sideStyle] = useWidth(size);
  let updatedSideStyle = {...sideStyle};
  if (size === 'small-card') {
      updatedSideStyle.fontSize = '24.5px';
  }

  return (
    <>
      <div className='metric-card-body' ref={ref}>
        <div className='big-value'>
          <FormattedDataPoint
            value={currentDevice.lastData.solarradiation}
            row={currentDevice.lastData}
            className='big-number'
            type='solarradiation'
            center={true}
            fontStyles={{
              mainStyle: mainFontStyle,
              suffixStyle: updatedSideStyle,
            }}
            availableWidth={width}
            roundDecimals={true}
          />
        </div>
      </div>
      <CardFooter
        values={[{
          name: "Today's Peak",
          value: <FormattedDataPoint
            value={path(['hl', 'solarradiation', 'h'],
            currentDevice.lastData)}
            row={currentDevice.lastData}
            type='solarradiation'/>
        }]}
      />
    </>
  )
}
import "./PM25Outdoor.scss";
import {CardFooter} from "../../components/CardFooter/CardFooter";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import { path } from 'ramda';
import { ReactComponent as Arrow } from '../../assets/pm25.svg';
import {useWidth} from "../../hooks/useWidth";

const sectionWidth = 30;
const min = 0;
const max = 180;

export function PM25Outdoor ({ currentDevice, size, type }) {
  const [width, ref, , , footerStyle] = useWidth(size);
  let pm25type;

  if(type === 'pm25'){
    pm25type = currentDevice.lastData.pm25;
  } else{
    pm25type = currentDevice.lastData.pm25_in;
  }
  
  if(pm25type < min) pm25type = min;
  if(pm25type > max) pm25type = max;

  const total = max - min;

  const totalAngle = 180;
  const initialArrowAngleOffset = -90;
  const circleCenterLeftPosition = 279;
  const circleCenterTopPosition = 129;
  const circleRadius = 146;
  const offset = pm25type - min;
  const angle = totalAngle * offset / total;
  let section = Math.ceil(angle / sectionWidth);
  if(section === 0) section = 1;

  const pm25OutdoorBands = [{
    cutoff: [0, 50],
    label: 'Good',
    degrees: [0, 28.4]
  }, {
    cutoff: [51, 100],
    label: 'Moderate',
    degrees: [29, 58.7]
  },{
    cutoff: [101, 150],
    label: 'Unhealthy for Sensitive Groups',
    degrees: [59.5, 89.7]
  },{
    cutoff: [151, 200],
    label: 'Unhealthy',
    degrees: [90.3, 121]
  }, {
    cutoff: [201, 300],
    label: 'Very Unhealthy',
    degrees: [122, 150.5]
  }, {
    cutoff: [301, 500],
    label: 'Hazardous',
    degrees: [152.5, 180]
  }];

  const degreesToRadians = degrees => degrees * (Math.PI / 180);

  const stuffForpm25Outdoor = value => {
    let v = value > 500 || isNaN(value) ? 500 : value;
    const pm25OutdoorBand = pm25OutdoorBands.find(band => v <= band.cutoff[1]);
    const angleDeg = pm25OutdoorBand.degrees[0] + (v - pm25OutdoorBand.cutoff[0]) * (pm25OutdoorBand.degrees[1] - pm25OutdoorBand.degrees[0]) / (pm25OutdoorBand.cutoff[1] - pm25OutdoorBand.cutoff[0]);
    const angleRad = degreesToRadians(angleDeg);
    const topOffset = Math.abs(circleRadius * Math.sin(angleRad));
    const leftOffset = Math.abs(circleRadius * Math.cos(angleRad)) * (angleDeg < 90 ? -1 : 1);
    const arrowStyle = {
      transform: `rotate(${initialArrowAngleOffset + angleDeg}deg)`,
      top:  (circleCenterTopPosition - topOffset) + 'px',
      left: (circleCenterLeftPosition + leftOffset) + 'px'
    };
    return {
      ...pm25OutdoorBand,
      arrowStyle,
      v
    }
  };

    const stuffs = {}

    stuffs.pm25 = stuffForpm25Outdoor(currentDevice.lastData[type])

  return (
    <>
      <div className='metric-card-body' ref={ref}>
        {size === 'large-card' ?
           <Arrow style={stuffs.pm25.arrowStyle} /> : ''
        }
        <div className='big-value'>
          <FormattedDataPoint
            row={currentDevice.lastData}
            value={currentDevice.lastData[type]}
            className='big-number'
            type={type}
            center={true}
          />
        </div>
      </div>
      <CardFooter values={[
          {
            name: "Avg",
            value: <FormattedDataPoint
              value={type === 'pm25' ? currentDevice.lastData.pm25_24h : currentDevice.lastData.pm25_in_24h}
              row={currentDevice.lastData}
              type={type=== 'pm25' ? 'pm25_24h' : 'pm25_in_24h'}
              suff=" "
              fontStyles={{
                mainStyle: footerStyle,
                suffixStyle: footerStyle,
              }}
              availableWidth={width / 3}
              roundDecimals={true}
            />
          },
          {name: stuffs.pm25.label , value: ''},
          {
            name: "Peak",
            value: <FormattedDataPoint
              value={type === 'pm25' ? path(['hl', 'pm25', 'h'], currentDevice.lastData) :  path(['hl', 'pm25_in', 'h'], currentDevice.lastData)}
              row={currentDevice.lastData}
              type='h'
              suff=" "
              fontStyles={{
                mainStyle: footerStyle,
                suffixStyle: footerStyle,
              }}
              availableWidth={width / 3}
              roundDecimals={true}
            />
          }
        ]}
        size={size}
      />
    </>
  )  
}
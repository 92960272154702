import React, {PureComponent} from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types';
import {flip, path, apply, map, slice, filter, pipe} from 'ramda'
import {dataHas} from '../../ambient'
import FormattedDataPoint from '../../components/FormattedDataPoint/FormattedDataPoint'
import moment from 'moment'
import "./RainWidget.scss"

export default class RainWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object.isRequired,
    now: PropTypes.number,
    size: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const {height} = this.state
    const {currentDevice, now, size} = this.props
    const {lastData} = currentDevice
    const data = lastData
    let widget = <div className="rain-widget hidden"/>
    const currentDataHas = dataHas(data);

    const config = [
      ['Rate', 'hourlyrainin'],
      ['Day', 'dailyrainin'],
      ['Event', 'eventrainin'],
      ['Week', 'weeklyrainin'],
      ['Month', 'monthlyrainin'],
      ['Year', 'yearlyrainin'],
      ['Total', 'totalrainin']
    ]
    const filterDataWeHave = filter(pipe(
      slice(1, 2),
      currentDataHas,
    ));
    let numberOfBars = 4
    if (size === "small-card") {
      numberOfBars = 3
    } else if (size === "medium-card") {
      numberOfBars = 3
    }
    const barsWereUsing = pipe(
      filterDataWeHave,
      slice(0, numberOfBars)
    )(config)
    const dataWereUsing = map(pipe(
      slice(1, 2),
      flip(path)(data)
    ))(barsWereUsing);
    const max = apply(Math.max)(dataWereUsing)

    const lineCreator = (val, k) => {
      const h = height ? height * (data[k] / max) : 0
      const rainStyle = {
        height: h < 22 ? 22 : h || 22
      }
      let rainClass = 'rain-wrap'
      if (data[k] === 0) {
        rainClass += ' zero'
      }
      return (
        <div className={rainClass} key={k}>
          <div className="rain-tube">
            <div
              ref={(el) => {
                if (el) {
                  this.setState({
                    height: el.offsetHeight - 3
                  })
                }
              }}
              className="bg"
            >
              <div className="rain" style={rainStyle}/>
            </div>
          </div>
          <div className="wrap">
            <div className="formatted-value">
              <FormattedDataPoint type={k} row={data}/>
            </div>
            <div className={classNames('label', val.toLowerCase())}>{val}</div>
          </div>
        </div>
      )
    }
    const mains = map(apply(lineCreator), barsWereUsing);

    if (mains.length > 0) {
      let cls = 'rain-widget'
      let add = ''
      if (currentDataHas('lastRain')) {
        cls += ' has-last-rain'
        const t = moment(new Date(data.lastRain))
        const n = now || Date.now()
        const daysDiff = Math.floor(Math.abs(moment.duration(t.diff(n)).asDays()))
        const lastRainMessage = daysDiff > 1 ? <span>
                                                  <span className='days-diff'>{daysDiff}
                                                  </span>
                                                  <span> days ago</span>
                                                </span>
          : t.from(n)
        add = <div className="last-rain">Last Rainfall: {lastRainMessage} </div>
      }

      widget =
        <div className={cls}>
          <div className="outer-wrap">
            {mains}
          </div>
          {add}
        </div>
    }
    return widget
  }
}

RainWidget.displayName = 'RainWidget'

import "./Time.scss";
import moment from "moment/moment";
import {useContext} from "react";
import {DataContext} from "../../contexts/DataContext";
export const Time = () => {
  const globalState = useContext(DataContext);
  let meridian = "";
  let now;
  if(!globalState.userData) return null;

  if(globalState.userData.settings.hour24 && globalState.userData.settings.hour24.unit === 1){
    now = moment().tz(globalState.tz).format("HH:mm");
  }
  else {
    now = moment().tz(globalState.tz).format("h:mm");
    meridian = moment().tz(globalState.tz).format("a");
  }
  const date = moment().tz(globalState.tz).format("dddd / MMMM D");
  return <div className="metric-card-body">
    <div className="central-value">
      <span style={{visibility: "hidden"}}>{meridian}</span>{now}<span>{meridian}</span>
    </div>
    <div className="week-date">
      {date}
    </div>
  </div>;
}
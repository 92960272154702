import React from 'react';
import FormattedDataPoint from '../../components/FormattedDataPoint/FormattedDataPoint'
import "./Lightning.scss"
import moment from 'moment';
import {CardFooter} from '../../components/CardFooter/CardFooter';
import {ReactComponent as Lightning} from './../../assets/lightning-white.svg';
import {useWidth} from "../../hooks/useWidth";
import {ResizableText} from "../../components/ResizableText/ResizableText";

export const Lighting = ({currentDevice, size}) => {
  const [width, ref, , , footerStyle] = useWidth(size);
  const {lastData} = currentDevice
  const {lightning_day, lightning_hour, lightning_time} = lastData
  let bottoms = [
    {name: 'Hourly', value: <div className="with-lightning">{lightning_hour}<Lightning/></div>},
    {
      name: 'Last',
      value: lightning_time ?
        <ResizableText text={moment(lightning_time).fromNow()} availableWidth={width / 3} styles={footerStyle}/>  :
        '- -'
    },
    {
      name: 'Distance',
      value: <FormattedDataPoint
        type="lightning_distance"
        row={lastData}
        fontStyles={{
          mainStyle: footerStyle,
          suffixStyle: footerStyle,
        }}
        availableWidth={width / 3}
        roundDecimals={true}
      />
    }
  ]
  return (
    <>
      <div className='metric-card-body' ref={ref}>
        <div className='big-value'>
          <FormattedDataPoint
            value={lightning_day}
            row={lastData}
            className='big-number'
            type='lightning_day'
            center={true}
          />
          <div className="labels">
            <Lightning/>
            <div className="text-label">Today</div>
          </div>
        </div>
      </div>
      <CardFooter values={bottoms} size={size}/>
    </>
  );
}

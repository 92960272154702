import "./Ambient.scss";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import {useWidth} from "../../hooks/useWidth";

export function Ambient ({ currentDevice, param, size }) {
  const [width, ref, mainFontStyle, sideStyle] = useWidth(size);
  return (
    <div className='metric-card-body' ref={ref}>
      <div className='big-value'>
        <FormattedDataPoint
          row={currentDevice.lastData}
          className='big-number'
          type={param}
          fontStyles={{
            mainStyle: mainFontStyle,
            suffixStyle: sideStyle,
          }}
          availableWidth={width}
          roundDecimals={true}
        />
      </div>
    </div>
  )
}
import React from 'react';
import classNames from 'classnames';
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import "./IndoorAirQuality.scss";
import { CardFooter } from '../../components/CardFooter/CardFooter';
import {useWidth} from "../../hooks/useWidth";

export const IndoorAirQuality = ({currentDevice, type= 'co2_in_aqin', size}) => {
  const [width, ref, mainFontStyle, sideStyle, footerStyle] = useWidth(size);

  const co2Bands = [{
    cutoff: [0, 600],
    label: 'Healthy',
    distance: [0, 18.6]
  }, {
    cutoff: [601, 1000],
    label: 'Moderate',
    distance: [18.6, 31]
  },{
    cutoff: [1001, 1500],
    label: size === 'small-card' ? 'Unhealthy for Sensitive' : 'Unhealthy for Sensitive Groups',
    distance: [32, 47.5]
  },{
    cutoff: [1501, 2000],
    label: 'Unhealthy',
    distance: [47.5, 64]
  }, {
    cutoff: [2001, 2500],
    label: 'Very Unhealthy',
    distance: [64, 80]
  }, {
    cutoff: [2501, 5000],
    label: 'Hazardous',
    distance: [80, 96]
  }];
  const stuffForPpm = value => {
    let v = value > 5000 ? 5000 : value
    const co2Band = co2Bands.find(band => v <= band.cutoff[1])
    const distance =  co2Band ? (co2Band.distance[0] + (v - co2Band.cutoff[0]) * (co2Band.distance[1] - co2Band.distance[0]) / (co2Band.cutoff[1] - co2Band.cutoff[0])) : '';
    return {
      ...co2Band,
      distance,
      v
    }
  }

  const stuff = stuffForPpm(currentDevice.lastData[type])
  let bottoms = [
    {
      name: 'Temp',
      value: <FormattedDataPoint
        value={currentDevice.lastData.pm_in_temp_aqin}
        type='pm_in_temp_aqin'
        row={currentDevice.lastData}
        fontStyles={{
          mainStyle: footerStyle,
          suffixStyle: footerStyle,
        }}
        availableWidth={width / 3}
        roundDecimals={true}
      />
    },
    { name: stuff.label, value: '' },
    {
      name: 'Humidity',
      value: <FormattedDataPoint
        value={currentDevice.lastData.pm_in_humidity_aqin}
        type='pm_in_humidity_aqin'
        row={currentDevice.lastData}
        fontStyles={{
          mainStyle: footerStyle,
          suffixStyle: footerStyle,
        }}
        availableWidth={width / 3}
        roundDecimals={true}
      />
    }
  ]
  if (type === 'co2_in') {
    bottoms = [{
      name: '24h',
      value: <FormattedDataPoint
        value={currentDevice.lastData.co2_in_24h}
        type='co2_in_24h'
        row={currentDevice.lastData}
      />
    }]
  }
  return (
    <>
      <div className='metric-card-body' ref={ref}>
        <div className='big-value'>
          <FormattedDataPoint
            before="CO2"
            value={currentDevice.lastData.co2_in_aqin}
            row={currentDevice.lastData}
            className='big-number'
            type='co2_in_aqin'
            center={true}
            fontStyles={{
              mainStyle: mainFontStyle,
              suffixStyle: sideStyle,
            }}
            availableWidth={width}
            roundDecimals={true}
          />
        </div>
          <div className={classNames("gauge", { sensitive: stuff.label === 'Unhealthy for Sensitive' })}>
            <div style={{ marginLeft: `${stuff.distance}%` }}
                 className={classNames("dot", { white: stuff.distance >= 66, black: stuff.distance < 66 })}/>
          </div>
      </div>
      <CardFooter values={bottoms} size={size}/>
    </>
  )
}
